// import React, { useEffect, useState } from "react";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
// import AILeadService from "../../../data-access/services/aiLeadServices";
// import { Backdrop, Box, Fade, Modal } from "@mui/material";
// import DetailsCallLeads from "./DetailsCallLeads";

// export default function CallLeads() {
//   const [leadData, setLeadData] = useState([]);
//   const [columns, setColumns] = useState([]);
//   const [error, setError] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [openDetailsCallLeadsModal, setOpenDetailsCallLeadsModal] =
//     useState(false);

//   const [date, setDate] = useState("2024-09");
//   const [cellValue, setCellValue] = useState("Missed call");
//   const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });

//   const style = {
//     position: "absolute",
//     top: "50%",
//     left: "50%",
//     transform: "translate(-50%, -50%)",
//     height: "auto",
//     width: "80%",
//     bgcolor: "background.paper",
//     border: "2px solid #184332",
//     boxShadow: 40,
//     p: 4,
//   };

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await AILeadService.getCallLeads();
//         const data = response?.data?.data || [];

//         // Extract unique column names dynamically
//         const uniqueColumns = Array.from(
//           new Set(data.flatMap((row) => Object.keys(row)))
//         );
//         setColumns(uniqueColumns);
//         setLeadData(data);
//       } catch (err) {
//         setError(err.message ?? "An error occurred while fetching data.");
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   const handleDetailsCallLeadsModalClose = () => {
//     setOpenDetailsCallLeadsModal(false);
//   };

//   const handleRowClick = (col, lead_type) => {
//     setDate(col);
//     setCellValue(lead_type);
//     setOpenDetailsCallLeadsModal(true);
//   };

//   const handleSort = (key) => {
//     let direction = "ascending";
//     if (sortConfig.key === key && sortConfig.direction === "ascending") {
//       direction = "descending";
//     }
//     setSortConfig({ key, direction });

//     const sortedData = [...leadData].sort((a, b) => {
//       if (a[key] === null || a[key] === undefined) return 1;
//       if (b[key] === null || b[key] === undefined) return -1;
//       if (direction === "ascending") {
//         return a[key] > b[key] ? 1 : -1;
//       }
//       return a[key] < b[key] ? 1 : -1;
//     });
//     setLeadData(sortedData);
//   };

//   return (
//     <>
//       <TableContainer component={Paper}>
//         <Table sx={{ minWidth: 650 }} aria-label="simple table">
//           <TableHead className="bg-[#0d271e]">
//             <TableRow>
//               {columns.map((column) => (
//                 <TableCell
//                   key={column}
//                   align="left"
//                   sx={{ whiteSpace: "nowrap", color: "#dcf1ea", cursor: "pointer" }}
//                   onClick={() => handleSort(column)}
//                 >
//                   {column === "lead_type" ? "Call Leads" : column}
//                   {sortConfig.key === column ? (
//                     sortConfig.direction === "ascending" ? " ↑" : " ↓"
//                   ) : null}
//                 </TableCell>
//               ))}
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {leadData.map((row, rowIndex) => (
//               <TableRow key={rowIndex}>
//                 {columns.map((col, colIndex) => (
//                   <TableCell
//                     key={colIndex}
//                     style={{
//                       backgroundColor: "inherit",
//                     }}
//                     onClick={() => handleRowClick(col, row?.lead_type)}
//                   >
//                     {row[col] || 0}
//                   </TableCell>
//                 ))}
//               </TableRow>
//             ))}
//             {/* Grand Total Row */}
//             <TableRow>
//               <TableCell style={{ fontWeight: "bold" }}>Grand Total</TableCell>
//               {columns.slice(1).map((col, colIndex) => (
//                 <TableCell key={colIndex} style={{ fontWeight: "bold" }}>
//                   {leadData.reduce((sum, row) => sum + (row[col] || 0), 0)}
//                 </TableCell>
//               ))}
//             </TableRow>
//           </TableBody>
//         </Table>
//       </TableContainer>
//       <Modal
//         aria-labelledby="transition-modal-title"
//         aria-describedby="transition-modal-description"
//         open={openDetailsCallLeadsModal}
//         onClose={handleDetailsCallLeadsModalClose}
//         closeAfterTransition
//         slots={{ backdrop: Backdrop }}
//         slotProps={{
//           backdrop: {
//             timeout: 500,
//           },
//         }}
//       >
//         <Fade in={openDetailsCallLeadsModal}>
//           <Box sx={style}>
//             <DetailsCallLeads
//               date={date}
//               cellValue={cellValue}
//               closeModal={handleDetailsCallLeadsModalClose}
//             />
//           </Box>
//         </Fade>
//       </Modal>
//     </>
//   );
// }

import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Modal,
  Fade,
  Box,
  Backdrop,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import DetailsCallLeads from "./DetailsCallLeads";

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: "#174736 ",
          color: "#ffffff",
          fontWeight: "bold",
        },
        body: {
          backgroundColor: "#ffffff",
          color: "#000000",
        },
      },
    },
  },
});

const columns = [
  "Call Leads",
  "2023-10",
  "2023-11",
  "2023-12",
  "2024-01",
  "2024-02",
  "2024-03",
  "2024-04",
  "2024-05",
  "2024-06",
  "2024-07",
];

const rows = [
  ["Abandon Call", 13, 9, 17, 14, 13, 14, 8, 13, 2, 6],
  ["Missed Call", 22, 15, 43, 54, 35, 19, 27, 31, 15, 12],
  ["New Patient Opportunity", 14, 10, 10, 12, 12, 11, 20, 14, 23, 60],
  ["Patient Booked", 33, 11, 31, 23, 30, 46, 21, 25, 23, 8],
  ["Question", 77, 84, 77, 58, 42, 53, 38, 53, 29, 44],
  ["Grand Total", 159, 129, 178, 161, 132, 143, 114, 136, 92, 130],
];

const PaginatedTable = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [date, setDate] = useState("2024-09");
  const [cellValue, setCellValue] = useState("Missed call");
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [openDetailsCallLeadsModal, setOpenDetailsCallLeadsModal] =
    useState(false);
  const handleDetailsCallLeadsModalClose = () => {
    setOpenDetailsCallLeadsModal(false);
  };

  const handleRowClick = (col, lead_type) => {
    setDate(col);
    setCellValue(lead_type);
    setOpenDetailsCallLeadsModal(true);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "auto",
    width: "80%",
    bgcolor: "background.paper",
    border: "2px solid #184332",
    boxShadow: 40,
    p: 4,
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <ThemeProvider theme={theme}>
      <TableContainer component={Paper} className="p-4">
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell key={index}>{column}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <TableCell onClick={()=> setOpenDetailsCallLeadsModal(true)} key={cellIndex}>{cell}</TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openDetailsCallLeadsModal}
        onClose={handleDetailsCallLeadsModalClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openDetailsCallLeadsModal}>
          <Box sx={style}>
            <DetailsCallLeads
              date={date}
              cellValue={cellValue}
              closeModal={handleDetailsCallLeadsModalClose}
            />
          </Box>
        </Fade>
      </Modal>
    </ThemeProvider>
  );
};

export default PaginatedTable;
