import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AILeadService from "../../../../data-access/services/aiLeadServices";
import { Backdrop, Box, Fade, Modal } from "@mui/material";
import DetailsCallLeads from "./DetailsCallLeads";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: "#174736",
          color: "#ffffff",
          fontWeight: "bold",
        },
        body: {
          backgroundColor: "#ffffff",
          color: "#000000",
        },
      },
    },
  },
});

export default function CallLeads() {
  const [leadData, setLeadData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openDetailsCallLeadsModal, setOpenDetailsCallLeadsModal] =
    useState(false);

  const [date, setDate] = useState("2024-09");
  const [cellValue, setCellValue] = useState("Missed call");
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "auto",
    width: "80%",
    bgcolor: "background.paper",
    border: "2px solid #184332",
    boxShadow: 40,
    p: 4,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await AILeadService.getCallLeads();
        const data = response?.data?.data || [];

        // Extract unique column names dynamically
        const uniqueColumns = Array.from(
          new Set(data.flatMap((row) => Object.keys(row)))
        );
        setColumns(uniqueColumns);
        setLeadData(data);
      } catch (err) {
        setError(err.message ?? "An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleDetailsCallLeadsModalClose = () => {
    setOpenDetailsCallLeadsModal(false);
  };

  const handleRowClick = (col, lead_type) => {
    setDate(col);
    setCellValue(lead_type);
    setOpenDetailsCallLeadsModal(true);
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });

    const sortedData = [...leadData].sort((a, b) => {
      if (a[key] === null || a[key] === undefined) return 1;
      if (b[key] === null || b[key] === undefined) return -1;
      if (direction === "ascending") {
        return a[key] > b[key] ? 1 : -1;
      }
      return a[key] < b[key] ? 1 : -1;
    });
    setLeadData(sortedData);
  };

  return (
    <ThemeProvider theme={theme}>
      <TableContainer
        component={Paper}
        sx={{ overflowX: "auto" }}
        className="p-4"
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead className="bg-[#0d271e]">
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={column}
                  sx={{
                    textAlign: index !== 0 ? "center" : "left",
                    cursor: "pointer",
                  }}
                  style={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                  onClick={() => handleSort(column)}
                >
                  {column === "lead_type" ? "Call Leads" : column}
                  {sortConfig.key === column
                    ? sortConfig.direction === "ascending"
                      ? " ↑"
                      : " ↓"
                    : null}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {leadData.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {columns.map((col, colIndex) => (
                  <TableCell
                    key={colIndex}
                    style={{
                      backgroundColor: "inherit",
                    }}
                    sx={{ textAlign: colIndex !== 0 ? "center" : "left" }}
                    onClick={() => handleRowClick(col, row?.lead_type)}
                  >
                    {row[col] || 0}
                  </TableCell>
                ))}
              </TableRow>
            ))}
            {/* Grand Total Row */}
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>Grand Total</TableCell>
              {columns.slice(1).map((col, colIndex) => (
                <TableCell
                  key={colIndex}
                  style={{ fontWeight: "bold" }}
                  sx={{ textAlign: "center"}}
                >
                  {leadData.reduce((sum, row) => sum + (row[col] || 0), 0)}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openDetailsCallLeadsModal}
        onClose={handleDetailsCallLeadsModalClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openDetailsCallLeadsModal}>
          <Box sx={style}>
            <DetailsCallLeads
              date={date}
              cellValue={cellValue}
              closeModal={handleDetailsCallLeadsModalClose}
            />
          </Box>
        </Fade>
      </Modal>
    </ThemeProvider>
  );
}
