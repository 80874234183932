import React from "react";
import ReactApexChart from "react-apexcharts";

const CallLeadsChart = () => {
  const series = [
    {
      name: "Abandon Call",
      data: [13, 9, 17, 14, 13, 14, 8, 13, 2, 6],
    },
    {
      name: "Missed Call",
      data: [22, 15, 43, 54, 35, 19, 27, 31, 15, 12],
    },
    {
      name: "New Patient Opportunity",
      data: [14, 10, 10, 12, 12, 11, 20, 14, 23, 60],
    },
    {
      name: "Patient Booked",
      data: [33, 11, 31, 23, 30, 46, 21, 25, 23, 8],
    },
    {
      name: "Question",
      data: [77, 84, 77, 58, 42, 53, 38, 53, 29, 44],
    },
  ];

  const options = {
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      height: 350,
    },
    xaxis: {
      categories: [
        "2023-10",
        "2023-11",
        "2023-12",
        "2024-01",
        "2024-02",
        "2024-03",
        "2024-04",
        "2024-05",
        "2024-06",
        "2024-07",
      ],
    },
    stroke: {
      curve: "smooth",
    },
    markers: {
      size: 4,
    },
    title: {
      text: "Call Leads Data Over Time",
      align: "center",
    },
  };

  return (
    <div>
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={350}
      />
    </div>
  );
};

export default CallLeadsChart;
