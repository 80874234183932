import React from "react";
import { Avatar, Box, Typography } from "@mui/material";
import HeaderBanner from "../../../../assets/images/WAIG-Header-Banner.png"

export default function CompanyLogoAndMoto() {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        // border: "2px solid #ddd",
        // padding: "20px",
        // borderRadius: "10px",
        // backgroundColor: "#f5f5f5",
      }}
    >
      {/* Avatar (Large logo with image) */}
      {/* <Avatar
        src={Logo} // Path to your logo image
        sx={{
          width: 100, // Adjust size of logo
          height: 100, // Adjust size of logo
          backgroundColor: "#f5f5f5", // Set a fallback background color if image is not loaded
        }}
      /> */}
      <img src={HeaderBanner} width={500} alt="" />

      {/* Title or Motto */}
      {/* <Box ml={2}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            color: "#0d271e",
            textTransform: "uppercase",
          }}
        >
          World AI Group
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontStyle: "italic",
            color: "#666",
            marginTop: "5px",
          }}
        >
          Your Growth, Our AI Expertise.
        </Typography>
      </Box> */}
    </Box>
  );
}
