// import React, { useEffect, useState } from "react";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
// import TablePagination from "@mui/material/TablePagination";
// import AILeadService from "../../../data-access/services/aiLeadServices";

// export default function GoogleRanking() {
//   const [leadData, setLeadData] = useState([]);
//   const [columns, setColumns] = useState([]);
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(5);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [sortConfig, setSortConfig] = useState({ key: null, direction: "" });

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await AILeadService.getGoogleSearchRankings();
//         const apiData = response?.data?.data || [];
//         if (apiData.length > 0) {
//           // Extract columns dynamically
//           const dynamicColumns = Object.keys(apiData[0]).map((key) => ({
//             id: key,
//             label: key === "Top queries" ? "Keywords" : key,
//           }));
//           setColumns(dynamicColumns);
//           setLeadData(apiData);
//         }
//       } catch (err) {
//         setError(err.message || "An error occurred while fetching data.");
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   const handleSort = (key) => {
//     let direction = "ascending";
//     if (sortConfig.key === key && sortConfig.direction === "ascending") {
//       direction = "descending";
//     }
//     setSortConfig({ key, direction });

//     const sortedData = [...leadData].sort((a, b) => {
//       if (a[key] === null || a[key] === undefined) return 1;
//       if (b[key] === null || b[key] === undefined) return -1;
//       if (direction === "ascending") {
//         return a[key] > b[key] ? 1 : -1;
//       }
//       return a[key] < b[key] ? 1 : -1;
//     });
//     setLeadData(sortedData);
//   };

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   if (loading) return <p>Loading...</p>;
//   if (error) return <p>Error: {error}</p>;

//   return (
//     <TableContainer component={Paper}>
//       <Table sx={{ minWidth: 650 }} aria-label="Google Ranking Table">
//         <TableHead className="bg-[#0d271e]">
//           <TableRow>
//             {columns.map((column) => (
//               <TableCell
//                 key={column.id}
//                 align="left"
//                 sx={{ whiteSpace: "nowrap", fontWeight: "bold", color: "#dcf1ea", cursor: "pointer" }}
//                 onClick={() => handleSort(column.id)}
//               >
//                 {column.label}
//                 {sortConfig.key === column.id ? (
//                   sortConfig.direction === "ascending" ? " ↑" : " ↓"
//                 ) : null}
//               </TableCell>
//             ))}
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {leadData
//             .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//             .map((row, rowIndex) => (
//               <TableRow key={rowIndex}>
//                 {columns.map((column) => (
//                   <TableCell
//                     key={column.id}
//                     style={{ backgroundColor: "white" }}
//                   >
//                     {row[column.id] ?? "-"}
//                   </TableCell>
//                 ))}
//               </TableRow>
//             ))}
//         </TableBody>
//       </Table>
//       <TablePagination
//         component="div"
//         count={leadData.length}
//         page={page}
//         onPageChange={handleChangePage}
//         rowsPerPage={rowsPerPage}
//         onRowsPerPageChange={handleChangeRowsPerPage}
//         rowsPerPageOptions={[5, 10, 25, 50, 100]}
//       />
//     </TableContainer>
//   );
// }

import React, { useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";
import { LeadGenerationContext } from "../../../contexts/LeadGenerationContext";

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: "#174736",
          color: "#ffffff",
          fontWeight: "bold",
        },
        body: {
          backgroundColor: "#ffffff",
          color: "#000000",
        },
      },
    },
  },
});

// const columns = ["No.", "Keywords", "2024-06", "2024-07", "2024-08", "2024-09"];

// const rows = [
//   [1, 'dental crown in "xxx"', 2, 4, 8, "7 (Page 1)"],
//   [2, 'dental fillings service in "xxx"', 8, 7, 7, "5 (Page 1)"],
//   [3, 'dentures in "xxx"', 7, 7, 7, "8 (Page 1)"],
//   [4, 'root canal services in "xxx"', 6, 9, 13, "12 (Page 2)"],
//   [5, 'best dental fillings in "xxx"', 9, 9, 8, "6 (Page 1)"],
//   [6, 'cosmetic dentist in "xxx"', 12, 10, 10, "11 (Page 2)"],
//   [7, 'Best dental care service in "', 4, 4, 3, "2 (Page 1)"],
//   [8, 'best root canal in "xxx"', 11, 10, 8, "10 (Page 1)"],
//   [9, "Teeth whitening services in '", 9, 9, 10, "12 (Page 2)"],
//   [10, 'Cosmetic dentist in "xxx"', 12, 10, 10, "13 (Page 2)"],
//   [11, 'top choice dental clinic in "xxx"', 1, 1, 1, "1 (Page 1)"],
//   [12, 'best dentistry in "xxx"', 6, 7, 6, "4 (Page 1)"],
//   [13, 'Best dentist in "xxx"', 9, 7, 5, "5 (Page 1)"],
//   [14, 'Emergency Dentist "xxx"', 7, 6, 6, "6 (Page 1)"],
//   [15, '24/7 dentist in "xxx"', 9, 8, 6, "15 (Page 2)"],
//   [16, 'Emergency Dental Service in "xxx"', 9, 6, 4, "4 (Page 1)"],
//   [17, 'Award winning dental care in "xxx"', 1, 1, 1, "1 (Page 1)"],
//   [18, 'Best pediatric dentistry service in "xxx"', 19, 18, 16, "15 (Page 2)"],
// ];

const columns = [
  "No.", "Keywords", "2024-06", "2024-07", "2024-08", "2024-09",
  "2024-10", "2024-11", "2024-12", "2025-01"
];

const rows = [
  [1, 'dental crown in "xxx"', 2, 4, 8, "7 (Page 1)", 6, 5, 6, "5 (Page 1)"],
  [2, 'dental fillings service in "xxx"', 8, 7, 7, "5 (Page 1)", 6, 5, 6, "4 (Page 1)"],
  [3, 'dentures in "xxx"', 7, 7, 7, "8 (Page 1)", 9, 10, 6, "10 (Page 1)"],
  [4, 'root canal services in "xxx"', 6, 9, 13, "12 (Page 2)", 11, 9, 6, "8 (Page 1)"],
  [5, 'best dental fillings in "xxx"', 9, 9, 8, "6 (Page 1)", 5, 4, 6, "3 (Page 1)"],
  [6, 'cosmetic dentist in "xxx"', 12, 10, 10, "11 (Page 2)", 10, 9, 6, "9 (Page 2)"],
  [7, 'Best dental care service in "xxx"', 4, 4, 3, "2 (Page 1)", 2, 1, 6, "1 (Page 1)"],
  [8, 'best root canal in "xxx"', 11, 10, 8, "10 (Page 1)", 9, 8, 6, "7 (Page 1)"],
  [9, "Teeth whitening services in 'xxx'", 9, 9, 10, "12 (Page 2)", 11, 10, 6, "9 (Page 2)"],
  [10, 'Cosmetic dentist in "xxx"', 12, 10, 10, "13 (Page 2)", 12, 11, 6, "10 (Page 2)"],
  [11, 'top choice dental clinic in "xxx"', 1, 1, 1, "1 (Page 1)", 1, 1, 6, "1 (Page 1)"],
  [12, 'best dentistry in "xxx"', 6, 7, 6, "4 (Page 1)", 5, 5, 6, "4 (Page 1)"],
  [13, 'Best dentist in "xxx"', 9, 7, 5, "5 (Page 1)", 4, 4, 6, "3 (Page 1)"],
  [14, 'Emergency Dentist "xxx"', 7, 6, 6, "6 (Page 1)", 5, 5, 6, "5 (Page 1)"],
  [15, '24/7 dentist in "xxx"', 9, 8, 6, "15 (Page 2)", 14, 13, 6, "12 (Page 2)"],
  [16, 'Emergency Dental Service in "xxx"', 9, 6, 4, "4 (Page 1)", 3, 3, 6, "2 (Page 1)"],
  [17, 'Award winning dental care in "xxx"', 1, 1, 1, "1 (Page 1)", 1, 1, 6, "1 (Page 1)"],
  [18, 'Best pediatric dentistry service in "xxx"', 19, 18, 16, "15 (Page 2)", 14, 13, 6, "12 (Page 2)"],
];


const PaginatedTable = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { googleRankingKeywords } = useContext(LeadGenerationContext); // Get selected keywords from context

  // Filter rows based on selected keywords
  const filteredRows =
  googleRankingKeywords.length > 0
      ? rows.filter((row) => googleRankingKeywords.includes(row[1]))
      : rows;

console.log("googleRankingKeywords.length  CC: ",googleRankingKeywords.length)
console.log("CC: ",(googleRankingKeywords.length === 0 || googleRankingKeywords.length > 7) ? "450px" : (googleRankingKeywords.length*50).toString()+"px")
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    // <div className="h-[450px] overflow-y-scroll">
    <div className={`h-[${(googleRankingKeywords.length === 0 || googleRankingKeywords.length > 7) ? "450px" : (googleRankingKeywords.length*50).toString()+"px"} overflow-y-scroll`}>
      <ThemeProvider theme={theme}>
        <TableContainer component={Paper} sx={{overflowX: "auto"}} className="p-4">
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    key={index}
                    sx={{ textAlign: index !== 0 ? "center" : "left" }}
                    style={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                  >
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => ( */}
              {filteredRows.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <TableCell
                      key={cellIndex}
                      sx={{ textAlign: cellIndex !== 0 ? "center" : "left" }}
                    >
                      {cell}
                      {cellIndex > 1 &&
                        typeof cell === "number" &&
                        typeof row[cellIndex - 1] === "number" &&
                        (cell > row[cellIndex - 1] ? (
                          <ArrowDownward
                            fontSize="small"
                            color="error"
                            sx={{ ml: 0.5 }}
                          />
                        ) : cell < row[cellIndex - 1] ? (
                          <ArrowUpward
                            fontSize="small"
                            color="success"
                            sx={{ ml: 0.5 }}
                          />
                        ) : null)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {/* <TablePagination
          rowsPerPageOptions={[5, 10]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
        </TableContainer>
      </ThemeProvider>
    </div>
  );
};

export default PaginatedTable;
