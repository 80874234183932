import React, { useEffect, useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TablePagination,
  Paper,
  CircularProgress,
} from "@mui/material";
import AILeadService from "../../../../data-access/services/aiLeadServices";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: "#174736",
          color: "#ffffff",
          fontWeight: "bold",
        },
        body: {
          backgroundColor: "#ffffff",
          color: "#000000",
        },
      },
    },
  },
});

const GoogleMapRanking = () => {
  const [columns, setColumns] = useState([]);
  const [leadData, setLeadData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "" });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await AILeadService.getGoogleMapRankingReports();
        const data = response?.data?.data || [];

        if (data.length > 0) {
          const dynamicColumns = Object.keys(data[0]); // Extract column names
          setColumns(dynamicColumns);
          setLeadData(data);
        } else {
          setLeadData([]);
        }
      } catch (err) {
        setError(err.message || "An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });

    const sortedData = [...leadData].sort((a, b) => {
      if (a[key] === null || a[key] === undefined) return 1;
      if (b[key] === null || b[key] === undefined) return -1;
      if (direction === "ascending") {
        return a[key] > b[key] ? 1 : -1;
      }
      return a[key] < b[key] ? 1 : -1;
    });
    setLeadData(sortedData);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="h-[450px] overflow-y-scroll">
      {loading ? (
        <div style={{ textAlign: "center", padding: "20px" }}>
          <CircularProgress />
        </div>
      ) : error ? (
        <div style={{ textAlign: "center", color: "red", padding: "20px" }}>
          Error: {error}
        </div>
      ) : (
        <ThemeProvider theme={theme}>
          <TableContainer
            component={Paper}
            sx={{ overflowX: "auto" }}
            className="p-4"
          >
            <Table>
              <TableHead className="bg-[#0d271e]">
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell
                      key={column}
                      onClick={() => handleSort(column)}
                      sx={{ textAlign: index !== 0 ? "center" : "left" }}
                      style={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                    >
                      {column}
                      {sortConfig.key === column
                        ? sortConfig.direction === "ascending"
                          ? " ↑"
                          : " ↓"
                        : null}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {leadData.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={columns.length} align="center">
                      No data available
                    </TableCell>
                  </TableRow>
                ) : (
                  // leadData
                  //   // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  //   .map((row, rowIndex) => (
                  //     <TableRow key={rowIndex}>
                  //       {columns.map((column, colIndex) => (
                  //         <TableCell
                  //           key={colIndex}
                  //           sx={{
                  //             textAlign: colIndex !== 0 ? "center" : "left",
                  //           }}
                  //         >
                  //           {row[column] !== null && row[column] !== undefined
                  //             ? row[column]
                  //             : "-"}
                  //           {colIndex > 1 &&
                  //             typeof column === "number" &&
                  //             typeof row[colIndex - 1] === "number" &&
                  //             (column > row[colIndex - 1] ? (
                  //               <ArrowDownward
                  //                 fontSize="small"
                  //                 color="error"
                  //                 sx={{ ml: 0.5 }}
                  //               />
                  //             ) : column < row[colIndex - 1] ? (
                  //               <ArrowUpward
                  //                 fontSize="small"
                  //                 color="success"
                  //                 sx={{ ml: 0.5 }}
                  //               />
                  //             ) : null)}
                  //         </TableCell>
                  //       ))}
                  //     </TableRow>
                  //   ))
                  leadData.map((row, rowIndex) => (
                    <TableRow key={rowIndex}>
                      {columns.map((column, colIndex) => {
                        const cellValue = row[column]; // Actual cell value
                        const prevCellValue =
                          colIndex > 0 ? row[columns[colIndex - 1]] : null; // Previous column value

                        return (
                          <TableCell
                            key={colIndex}
                            sx={{
                              textAlign: colIndex !== 0 ? "center" : "left",
                            }}
                          >
                            {cellValue !== null && cellValue !== undefined
                              ? cellValue
                              : "-"}
                            {colIndex > 1 &&
                              typeof cellValue === "number" &&
                              typeof prevCellValue === "number" &&
                              (cellValue > prevCellValue ? (
                                <ArrowDownward
                                  fontSize="small"
                                  color="error"
                                  sx={{ ml: 0.5 }}
                                />
                              ) : cellValue < prevCellValue ? (
                                <ArrowUpward
                                  fontSize="small"
                                  color="success"
                                  sx={{ ml: 0.5 }}
                                />
                              ) : null)}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
            {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={leadData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
          </TableContainer>
        </ThemeProvider>
      )}
    </div>
  );
};

export default GoogleMapRanking;
