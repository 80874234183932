import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AILeadService from "../../../../data-access/services/aiLeadServices";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: "#174736",
          color: "#ffffff",
          fontWeight: "bold",
        },
        body: {
          backgroundColor: "#ffffff",
          color: "#000000",
        },
      },
    },
  },
});

export default function SocialMediaReportFacebook() {
  const [leadData, setLeadData] = useState([]);
  const [columns, setColumns] = useState(["Actions"]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await AILeadService.getSocialMediaFacebookReports();
        const apiData = response?.data?.data || [];

        if (apiData.length > 0) {
          // Extract unique months from the response keys
          const monthKeys = Object.keys(apiData[0]).filter(
            (key) => key !== "insight_type"
          );
          setColumns(["Actions", ...monthKeys]);

          // Map API data into the required format
          const formattedData = apiData.map((item) => {
            const { insight_type, ...monthlyData } = item;
            return {
              Actions: insight_type,
              ...monthlyData,
            };
          });
          setLeadData(formattedData);
        } else {
          setLeadData([]);
        }
      } catch (err) {
        setError(err.message || "An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <ThemeProvider theme={theme}>
      <TableContainer
        component={Paper}
        sx={{ overflowX: "auto" }}
        className="p-4"
      >
        <Table sx={{ minWidth: 650 }} aria-label="Social Media Report Table">
          <TableHead className="bg-[#0d271e]">
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={column}
                  sx={{ textAlign: index !== 0 ? "center" : "left" }}
                  style={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                >
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {leadData.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {columns.map((column) => (
                  <TableCell
                    key={column}
                    style={{
                      backgroundColor: "white",
                    }}
                  >
                    {row[column] !== null && row[column] !== undefined
                      ? row[column]
                      : 0}
                  </TableCell>
                ))}
              </TableRow>
            ))} */}

            {leadData.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {columns.map((column, colIndex) => {
                  const cellValue = row[column]; // Current cell value
                  const prevCellValue =
                    colIndex > 1 ? row[columns[colIndex - 1]] : null; // Previous cell value

                  return (
                    <TableCell
                      key={column}
                      sx={{ textAlign: colIndex !== 0 ? "center" : "left" }}
                    >
                      {cellValue !== null && cellValue !== undefined
                        ? cellValue
                        : 0}

                      {/* Show icons for numeric values in month columns */}
                      {colIndex > 1 &&
                        typeof cellValue === "number" &&
                        typeof prevCellValue === "number" &&
                        (cellValue > prevCellValue ? (
                          <ArrowUpward
                            fontSize="small"
                            color="success"
                            sx={{ ml: 0.5 }}
                          />
                        ) : cellValue < prevCellValue ? (
                          <ArrowDownward
                            fontSize="small"
                            color="error"
                            sx={{ ml: 0.5 }}
                          />
                        ) : null)}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ThemeProvider>
  );
}
