import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TableSortLabel,
  TextField,
} from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";

const StickyTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  whiteSpace: "nowrap",
  position: "sticky",
  left: 0,
  // background: theme.palette.background.default,
  zIndex: 1000,
}));

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: "#174736",
          color: "#ffffff",
          fontWeight: "bold",
        },
        body: {
          backgroundColor: "#ffffff",
          color: "#000000",
        },
      },
    },
  },
});

const columns = [
  "YearMonth",
  "Name",
  "Email",
  "Phone Number",
  "City",
  "Cause",
  "Date",
  "1st Attempt",
  "2nd Attempt",
  "Final Attempt",
  "Status",
  "Office Notes",
];

const rows = [
  [
    "2023-02",
    "Alex Ofori",
    "oforialex393@gmail.com",
    "+16477028078",
    "Toronto Ontario",
    "Dental Implants",
    "January 31",
    "email, text, Call Booked",
    "—",
    "—",
    "Yes",
    "Booked on Feb 6th @5:00 pm",
  ],
  [
    "2023-02",
    "Prince Marvis",
    "mayorofontario724@gmail.com",
    "+14374106120",
    "Toronto",
    "Teeth Whitening",
    "January 31",
    "email, wcb Booked",
    "—",
    "—",
    "Yes",
    "Booked on Feb 6th @3:30 pm",
  ],
  [
    "2023-04",
    "Ian Joseph",
    "captainjoseph22@gmail.com",
    "+16472926676",
    "Brampton",
    "Dental Implants",
    "February 8",
    "NA, Booked",
    "NA, Lvm",
    "BOOKED ALREADY",
    "Yes",
    "—",
  ],
  [
    "2023-04",
    "Chris Hampton",
    "champton1475@gmail.com",
    "+14166240070",
    "Toronto",
    "Dental Implants",
    "February 11",
    "text, Email",
    "Booked on March 11th",
    "—",
    "Yes",
    "—",
  ],
  [
    "2023-06",
    "Jagdeo Ramoutar",
    "Jagdeo@tfnrealty.com",
    "+16479951580",
    "Woodbridge",
    "Dental Implants",
    "February 11",
    "ALREADY Booked",
    "Came on Feb 12th",
    "—",
    "Yes",
    "—",
  ],
  [
    "2023-07",
    "Ian Joseph",
    "captainjoseph22@gmail.com",
    "+16472926676",
    "Brampton",
    "Dental Implants",
    "February 11",
    "Booked",
    "—",
    "—",
    "Yes",
    "—",
  ],
  [
    "2023-08",
    "John Kodric",
    "johnkodric46@gmail.com",
    "+14168453327",
    "Brampton",
    "Dental Implants",
    "February 12",
    "NA, LVM, Email, TEXT",
    "Booked",
    "—",
    "Yes",
    "—",
  ],
  [
    "2023-09",
    "Anthony del Monte",
    "etnom_anthony@yahoo.com",
    "+16475173063",
    "Ontario",
    "Dental Implants",
    "February 13",
    "Email, text, call LVM",
    "Booked",
    "—",
    "Yes",
    "PT replied back to our email... I emailed him back and also called him",
  ],
  [
    "2023-09",
    "Rose",
    "lilak123@hotmail.com",
    "+16473305000",
    "Toronto",
    "Dental Implants",
    "February 14",
    "Text, Call Booked",
    "—",
    "—",
    "Yes",
    "Showed up / Aman",
  ],
  [
    "2023-09",
    "Raul",
    "rortega1467@gmail.com",
    "+16475546989",
    "Etobicoke",
    "Dental Implants",
    "February 19",
    "Email, text",
    "Booked",
    "—",
    "Yes",
    "—",
  ],
  [
    "2023-10",
    "Ferro Fausto",
    "ferrofausto@hotmail.com",
    "+14167888900",
    "Toronto",
    "Dental Implants",
    "February 19",
    "Email, text, Booked",
    "—",
    "—",
    "Yes",
    "—",
  ],
  [
    "2023-09",
    "Rocky Reno",
    "rockynparay@gmail.com",
    "+14166164307",
    "Toronto",
    "Dental Implants",
    "February 19",
    "Email, NA LVM",
    "Booked already",
    "—",
    "Yes",
    "—",
  ],
  [
    "2023-09",
    "Luis Angel Guzman Orellan",
    "ING.angel93@hotmail.com",
    "+16474511708",
    "Toronto",
    "Dental Implants",
    "February 19",
    "Email",
    "Booked already",
    "—",
    "Yes",
    "PT called on Feb 16th to book / Aman. Cancelled his appointment / Aman",
  ],
  [
    "2023-09",
    "Valette Williams",
    "valkay22@outlook.com",
    "+16479284640",
    "Toronto",
    "Dental Implants",
    "February 19",
    "Email",
    "Booked",
    "—",
    "Yes",
    "—",
  ],
  [
    "2023-09",
    "Graziano Villa",
    "villalandscape@gmail.com",
    "+14169363397",
    "Richmond Hill",
    "Dental Implants",
    "February 25",
    "Email, Text, Call",
    "Booked",
    "—",
    "Yes",
    "—",
  ],
  [
    "2023-09",
    "Mary Gachero",
    "sweetmary8@yahoo.com",
    "+14372277060",
    "Toronto",
    "Dental Implants",
    "February 25",
    "Booked",
    "—",
    "—",
    "Yes",
    "—",
  ],
  [
    "2023-09",
    "Adonis Foye",
    "adonisfoye@hotmail.com",
    "+16475610004",
    "Ontario",
    "Dental Implants",
    "February 27",
    "Booked",
    "—",
    "—",
    "Yes",
    "—",
  ],
  [
    "2023-09",
    "Veronica Torres",
    "torresfuentesmariaveronica@gmail.com",
    "+14372134209",
    "Toronto",
    "Dental Implants",
    "February 27",
    "Called, booked",
    "—",
    "—",
    "Yes",
    "—",
  ],
];

const InfoLeadsDetailsTable = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });
  const [filters, setFilters] = useState(Array(columns.length).fill(""));
  const [editableRows, setEditableRows] = useState(
    rows.map((row) => row.map((cell) => (cell === "—" ? "" : cell))) // Initialize editable cells
  );

  const handleRowChange = (rowIndex, columnIndex, value) => {
    const updatedRows = [...editableRows];
    updatedRows[rowIndex][columnIndex] = value;
    setEditableRows(updatedRows);
  };

  const handleSave = () => {
    // Here you can save the data to the backend or update the rows state.
    console.log("Saved rows: ", editableRows);
  };

  const handleSort = (columnIndex) => {
    setSortConfig((prev) => ({
      key: columnIndex,
      direction:
        prev.key === columnIndex && prev.direction === "asc" ? "desc" : "asc",
    }));
  };

  const handleFilterChange = (index, value) => {
    const updatedFilters = [...filters];
    updatedFilters[index] = value;
    setFilters(updatedFilters);
  };

  const filteredRows = rows.filter((row) =>
    row.every((cell, index) =>
      cell.toString().toLowerCase().includes(filters[index].toLowerCase())
    )
  );

  const sortedRows = [...filteredRows].sort((a, b) => {
    const key = sortConfig.key;
    if (key === "") return 0;

    const aValue = a[key] || "";
    const bValue = b[key] || "";

    if (!isNaN(aValue) && !isNaN(bValue)) {
      return sortConfig.direction === "asc" ? aValue - bValue : bValue - aValue;
    } else {
      return sortConfig.direction === "asc"
        ? aValue.toString().localeCompare(bValue.toString())
        : bValue.toString().localeCompare(aValue.toString());
    }
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const generatePopupData = (ln) => {
    return Array.from({ length: ln }, (_, i) => ({
      Name: "Alex Ofori",
      Email: "oforialex393@gmail.com",
      "Phone Number": "+16477028078",
      City: "Toronto Ontario",
      Cause: "Dental Implants",
      Date: "January 31",
      "1st Attempt": "email",
      "2nd Attempt": "text",
      "Final Attempt": "Call Booked",
      "Appointment Booked": "Yes",
      Notes: "Booked on Feb 6th @5:00 pm",
      "Office Note": "Escalated case",
    }));
  };

  return (
    <ThemeProvider theme={theme}>
      <TableContainer
        component={Paper}
        sx={{ overflowX: "auto" }}
        // className="p-4"
      >
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column, index) =>
                index === 0 ? (
                  <StickyTableCell
                    key={index}
                    style={{ whiteSpace: "nowrap" }}
                    onClick={() => handleSort(index)}
                  >
                    <TableSortLabel
                      active={sortConfig.key === index}
                      direction={
                        sortConfig.key === index ? sortConfig.direction : "asc"
                      }
                      sx={{
                        color: "white", // Make the text color white
                        "&:hover": {
                          color: "white", // Ensure the color stays white when hovered
                        },
                      }}
                    >
                      {column}
                    </TableSortLabel>
                  </StickyTableCell>
                ) : (
                  <TableCell
                    key={index}
                    style={{ whiteSpace: "nowrap" }}
                    onClick={() => handleSort(index)}
                  >
                    <TableSortLabel
                      active={sortConfig.key === index}
                      direction={
                        sortConfig.key === index ? sortConfig.direction : "asc"
                      }
                      sx={{
                        color: "white", // Make the text color white
                        "&:hover": {
                          color: "white", // Ensure the color stays white when hovered
                        },
                      }}
                    >
                      {column}
                    </TableSortLabel>
                  </TableCell>
                )
              )}
            </TableRow>
            <TableRow>
              {columns.map((_, index) => (
                <TableCell key={index}>
                  <TextField
                    size="small"
                    variant="outlined"
                    placeholder="Filter..."
                    value={filters[index]}
                    onChange={(e) => handleFilterChange(index, e.target.value)}
                    // sx={{ width: "100%", color: "white" }}
                    sx={{
                      "& .MuiInputBase-input": {
                        color: "white", // Text color for the input field
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "white", // Border color for the input field
                        },
                        "&:hover fieldset": {
                          borderColor: "white", // Border color on hover
                        },
                      },
                    }}
                  />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => ( */}
            {sortedRows.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {row.map((cell, cellIndex) =>
                  cellIndex === 0 ? (
                    <StickyTableCell
                      key={cellIndex}
                      sx={{ textAlign: "left" }}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {cell}
                    </StickyTableCell>
                  ) : cellIndex >= 6 ? (
                    <TableCell key={cellIndex}>
                      <TextField
                        size="small"
                        variant="outlined"
                        value={editableRows[rowIndex][cellIndex]}
                        onChange={(e) =>
                          handleRowChange(rowIndex, cellIndex, e.target.value)
                        }
                      />
                    </TableCell>
                  ) : (
                    <TableCell
                      key={cellIndex}
                      sx={{ textAlign: "left" }}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {cell}
                    </TableCell>
                  )
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </TableContainer>
    </ThemeProvider>
  );
};

export default InfoLeadsDetailsTable;
