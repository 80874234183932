import React, { useState } from "react";
import CallLeadsDetailsTable from "../CallLeadsDetailsTable";
import InfoLeadsDetailsTable from "./InfoLeadsDetailsTable";
import CampaignDetails from "./CampaignDetails";
import NavAndTopBar from "../../../shared/layouts/NavAndTopBar";
import CompanyLogoAndMoto from "../CompanyLogoAndMoto";

export default function LeadReportDetails() {
  const [detailType, setDetailsType] = useState("call");
  return (
    <NavAndTopBar>
      <div className="bg-white p-4 flex flex-col gap-8">
        <CompanyLogoAndMoto />
        <div className="py-5">
          <div className="flex justify-center">
            <div className="flex flex-col justify-center items-center">
              <p className="golden-txt  font-bold text-2xl my-2">
                AI Lead Report {detailType} Details
              </p>
            </div>
          </div>
          <div className="flex justify-evenly items-center bg-white shadow-lg rounded-xl  w-[220px] h-[30px] my-4">
            <div
              className={`flex items-center justify-center w-[70px] h-[25px] ${
                detailType === "call" ? "bg-[#184332]" : "bg-white"
              } rounded-[10px] cursor-pointer`}
              onClick={() => setDetailsType("call")}
            >
              <p
                className={`${
                  detailType === "call"
                    ? "button-linear-text"
                    : "button-dashboard-tab-text"
                }`}
                style={{ textTransform: "capitalize" }}
              >
                Call
              </p>
            </div>
            <div
              className={`flex items-center justify-center w-[70px] h-[25px] ${
                detailType === "info" ? "bg-[#184332]" : "bg-white"
              } rounded-[10px] cursor-pointer`}
              onClick={() => setDetailsType("info")}
            >
              <p
                className={`${
                  detailType === "info"
                    ? "button-linear-text"
                    : "button-dashboard-tab-text"
                }`}
                style={{ textTransform: "capitalize" }}
              >
                Info
              </p>
            </div>
            <div
              className={`flex items-center justify-center w-[70px] h-[25px] ${
                detailType === "campaign" ? "bg-[#184332]" : "bg-white"
              } rounded-[10px] cursor-pointer`}
              onClick={() => setDetailsType("campaign")}
            >
              <p
                className={`${
                  detailType === "campaign"
                    ? "button-linear-text"
                    : "button-dashboard-tab-text"
                }`}
                style={{ textTransform: "capitalize" }}
              >
                Campaign
              </p>
            </div>
          </div>
          {detailType === "call" && <CallLeadsDetailsTable />}
          {detailType === "info" && <InfoLeadsDetailsTable />}
          {detailType === "campaign" && <CampaignDetails />}
        </div>
      </div>
    </NavAndTopBar>
  );
}
