// import React from "react";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";

// export default function GMBReports() {
//   const data = [
//     {
//       action: "Business interactions",
//       june: 225,
//       july: 326,
//       august: 317,
//       september: 301,
//     },
//     { action: "Calls", june: 60, july: 71, august: 105, september: 106 },
//     { action: "Website clicks", june: 44, july: 71, august: 70, september: 72 },
//     { action: "GMB Post", june: 4, july: 3, august: 7, september: 7 },
//   ];

//   return (
//     <TableContainer component={Paper}>
//       <Table sx={{ minWidth: 650 }} aria-label="simple table">
//         <TableHead className="bg-[#0d271e]">
//           <TableRow>
//             <TableCell sx={{ whiteSpace: "nowrap", fontWeight: "bold", color: "#dcf1ea" }}>
//               Actions
//             </TableCell>
//             <TableCell sx={{ whiteSpace: "nowrap", fontWeight: "bold", color: "#dcf1ea" }}>
//               June
//             </TableCell>
//             <TableCell sx={{ whiteSpace: "nowrap", fontWeight: "bold", color: "#dcf1ea" }}>
//               July
//             </TableCell>
//             <TableCell sx={{ whiteSpace: "nowrap", fontWeight: "bold", color: "#dcf1ea" }}>
//               August
//             </TableCell>
//             <TableCell sx={{ whiteSpace: "nowrap", fontWeight: "bold", color: "#dcf1ea" }}>
//               September
//             </TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {data.map((row) => (
//             <TableRow key={row.action}>
//               <TableCell>{row.action}</TableCell>
//               <TableCell>{row.june}</TableCell>
//               <TableCell>{row.july}</TableCell>
//               <TableCell>{row.august}</TableCell>
//               <TableCell>{row.september}</TableCell>
//             </TableRow>
//           ))}
//         </TableBody>
//       </Table>
//     </TableContainer>
//   );
// }

import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: "#174736 ",
          color: "#ffffff",
          fontWeight: "bold",
        },
        body: {
          backgroundColor: "#ffffff",
          color: "#000000",
        },
      },
    },
  },
});

// const columns = ["Actions", "2024-06", "2024-07", "2024-08", "2024-09"];

// const rows = [
//   ["Business interactions", 225, 326, 317, 301],
//   ["Calls", 60, 71, 105, 106],
//   ["Website clicks", 44, 71, 70, 72],
//   ["GMB Post", 4, 3, 7, 7],
// ];

const columns = [
  "Actions", "2024-06", "2024-07", "2024-08", "2024-09",
  "2024-10", "2024-11", "2024-12", "2025-01"
];

const rows = [
  ["Business interactions", 225, 326, 317, 301, 310, 320, 330, 340],
  ["Calls", 60, 71, 105, 106, 110, 115, 120, 125],
  ["Website clicks", 44, 71, 70, 72, 75, 78, 80, 83],
  ["GMB Post", 4, 3, 7, 7, 6, 8, 9, 10],
];


const PaginatedTable = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <ThemeProvider theme={theme}>
      <TableContainer
        component={Paper}
        sx={{ overflowX: "auto" }}
        className="p-4"
      >
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  sx={{ textAlign: index !== 0 ? "center" : "left" }}
                  style={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                >
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => ( */}
            {rows.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <TableCell
                    key={cellIndex}
                    sx={{ textAlign: cellIndex !== 0 ? "center" : "left" }}
                  >
                    {cell}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </TableContainer>
    </ThemeProvider>
  );
};

export default PaginatedTable;
