import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Modal,
  Box,
  Typography,
} from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";

const StickyTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  whiteSpace: "nowrap",
  position: "sticky",
  left: 0,
  // background: theme.palette.background.default,
  zIndex: 1000,
}));

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: "#174736",
          color: "#ffffff",
          fontWeight: "bold",
        },
        body: {
          backgroundColor: "#ffffff",
          color: "#000000",
        },
      },
    },
  },
});

// const columns = ["Info Leads", "2023-10", "2023-11", "2023-12", "2024-01", "2024-02", "2024-03", "2024-04", "2024-05", "2024-06", "2024-07"];

// const rows = [
//   ["Call Back", 4, 2, 9, 17, 1, 1, 19, 9, 16, 8],
//   ["No Answer", 3, 13, 16, 9, 7, 19, 15, 2, 12, 1],
//   ["Not Interested", 2, 12, 17, 11, 16, 16, 4, 11, 10, 4],
//   ["Booked", 15, 20, 30, 20, 18, 2, 7, 8, 1, 8],
//   ["Interested - Call Back", 0, 1, 9, 6, 5, 13, 19, 16, 11, 11],
//   ["Not Contacted", 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
//   ["Grand Total", 24, 48, 81, 63, 47, 51, 64, 10, 7, 1],
// ];
const modalColumns = [
  "Name",
  "Email",
  "Phone Number",
  "City",
  "Cause",
  "Date",
  "1st Attempt",
  "2nd Attempt",
  "Final Attempt",
  "Appointment Booked",
  "Notes",
  "Office Note",
];

const columns = [
  "Info Leads",
  "2023-10",
  "2023-11",
  "2023-12",
  "2024-01",
  "2024-02",
  "2024-03",
  "2024-04",
  "2024-05",
  "2024-06",
  "2024-07",
  "2024-08",
  "2024-09",
  "2024-10",
  "2024-11",
  "2024-12",
  "2025-01",
];

const rows = [
  {
    lead: "Call Back",
    data: [4, 2, 9, 17, 1, 1, 19, 9, 16, 8, 12, 10, 14, 15, 11, 13],
  },
  {
    lead: "No Answer",
    data: [3, 13, 16, 9, 7, 19, 15, 2, 12, 1, 5, 8, 11, 7, 6, 10],
  },
  {
    lead: "Not Interested",
    data: [2, 12, 17, 11, 16, 16, 4, 11, 10, 4, 6, 9, 8, 12, 10, 7],
  },
  {
    lead: "Booked",
    data: [15, 20, 30, 20, 18, 2, 7, 8, 1, 8, 9, 11, 13, 10, 12, 14],
  },
  {
    lead: "Interested - Call Back",
    data: [0, 1, 9, 6, 5, 13, 19, 16, 11, 11, 14, 17, 13, 12, 15, 18],
  },
  {
    lead: "Not Contacted",
    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 3, 2, 1, 2],
  },
  {
    lead: "Interested - Call Back",
    data: [24, 48, 81, 63, 47, 51, 64, 10, 7, 1, 18, 22, 29, 28, 27, 31],
  },
];

const PaginatedTable = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [open, setOpen] = useState(false);
  const [popupTitle, setPopupTitle] = useState("");
  const [popupData, setPopupData] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleClose = () => setOpen(false);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const generatePopupData = (ln) => {
    return Array.from({ length: ln }, (_, i) => ({
      Name: "Alex Ofori",
      Email: "oforialex393@gmail.com",
      "Phone Number": "+16477028078",
      City: "Toronto Ontario",
      Cause: "Dental Implants",
      Date: "January 31",
      "1st Attempt": "email",
      "2nd Attempt": "text",
      "Final Attempt": "Call Booked",
      "Appointment Booked": "Yes",
      Notes: "Booked on Feb 6th @5:00 pm",
      "Office Note": "Escalated case",
    }));
  };

  const handleOpen = (lead, month, value) => {
    setPopupTitle(`${lead} - ${month} (Value: ${value})`);
    setPopupData(generatePopupData(value));
    setOpen(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <TableContainer
        component={Paper}
        sx={{ overflowX: "auto" }}
        // className="p-4"
      >
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column, index) =>
                index === 0 ? (
                  <StickyTableCell key={index} style={{ whiteSpace: "nowrap" }}>
                    {column}
                  </StickyTableCell>
                ) : (
                  <TableCell key={index} style={{ whiteSpace: "nowrap" }}>
                    {column}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => ( */}
            {rows.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                <StickyTableCell
                  style={{
                    whiteSpace: "nowrap",
                  }}
                >
                  {row.lead}
                </StickyTableCell>
                {row.data.map((value, index) => (
                  <TableCell
                    key={index}
                    style={{
                      cursor: "pointer",
                    }}
                    sx={{ textAlign: "center" }}
                    onClick={() => handleOpen(row.lead, columns[index], value)}
                  >
                    {value}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </TableContainer>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxHeight: "90vh",
            bgcolor: "white",
            boxShadow: 24,
            p: 3,
            borderRadius: "10px",
            overflowY: "auto",
          }}
        >
          <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
            {popupTitle}
          </Typography>

          <ThemeProvider theme={theme}>
            <TableContainer
              component={Paper}
              sx={{ maxHeight: "50vh", overflowY: "auto" }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    {modalColumns.map((col) => (
                      <TableCell key={col}>{col}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {popupData
                    // .slice(popupPage * popupRowsPerPage, popupPage * popupRowsPerPage + popupRowsPerPage)
                    .map((item, index) => (
                      <TableRow key={index}>
                        {modalColumns.map((col) => (
                          <TableCell
                            key={col}
                            style={
                              index === 0
                                ? {
                                    position: "sticky",
                                    left: 0,
                                    backgroundColor: "#fff",
                                    zIndex: 1,
                                  }
                                : {}
                            }
                          >
                            {item[col] || "-"}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            {/* <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={popupData.length}
              rowsPerPage={popupRowsPerPage}
              page={popupPage}
              onPageChange={handlePopupChangePage}
              onRowsPerPageChange={handlePopupChangeRowsPerPage}
            /> */}
          </ThemeProvider>
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default PaginatedTable;
