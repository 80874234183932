import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AILeadService from "../../../../data-access/services/aiLeadServices";
import { CircularProgress } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: "#174736",
          color: "#ffffff",
          fontWeight: "bold",
        },
        body: {
          backgroundColor: "#ffffff",
          color: "#000000",
        },
      },
    },
  },
});

export default function GMBReports() {
  const [columns, setColumns] = useState([]);
  const [leadData, setLeadData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "" });
  // const data = [
  //   {
  //     action: "Business interactions",
  //     june: 225,
  //     july: 326,
  //     august: 317,
  //     september: 301,
  //   },
  //   { action: "Calls", june: 60, july: 71, august: 105, september: 106 },
  //   { action: "Website clicks", june: 44, july: 71, august: 70, september: 72 },
  //   { action: "GMB Post", june: 4, july: 3, august: 7, september: 7 },
  // ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await AILeadService.getGMBReports();
        const data = response?.data?.data || [];

        if (data.length > 0) {
          const dynamicColumns = Object.keys(data[0]); // Extract column names
          setColumns(dynamicColumns);
          setLeadData(data);
        } else {
          setLeadData([]);
        }
      } catch (err) {
        setError(err.message || "An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });

    const sortedData = [...leadData].sort((a, b) => {
      if (a[key] === null || a[key] === undefined) return 1;
      if (b[key] === null || b[key] === undefined) return -1;
      if (direction === "ascending") {
        return a[key] > b[key] ? 1 : -1;
      }
      return a[key] < b[key] ? 1 : -1;
    });
    setLeadData(sortedData);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="h-[220px] overflow-y-scroll">
      <ThemeProvider theme={theme}>
        {loading ? (
          <div style={{ textAlign: "center", padding: "20px" }}>
            <CircularProgress />
          </div>
        ) : error ? (
          <div style={{ textAlign: "center", color: "red", padding: "20px" }}>
            Error: {error}
          </div>
        ) : (
          <TableContainer sx={{ overflowX: "auto" }} className="p-4">
            <Table>
              <TableHead className="bg-[#0d271e]">
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell
                      key={column}
                      onClick={() => handleSort(column)}
                      sx={{ textAlign: index !== 0 ? "center" : "left" }}
                      style={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                    >
                      {column}
                      {sortConfig.key === column
                        ? sortConfig.direction === "ascending"
                          ? " ↑"
                          : " ↓"
                        : null}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {leadData.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={columns.length} align="center">
                      No data available
                    </TableCell>
                  </TableRow>
                ) : (
                  leadData
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, rowIndex) => (
                      <TableRow key={rowIndex}>
                        {columns.map((column, colIndex) => (
                          <TableCell
                            key={colIndex}
                            sx={{
                              textAlign: colIndex !== 0 ? "center" : "left",
                            }}
                          >
                            {row[column] !== null && row[column] !== undefined
                              ? row[column]
                              : "-"}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                )}
              </TableBody>
            </Table>
            {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={leadData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
          </TableContainer>
        )}
      </ThemeProvider>
    </div>
  );
}
