import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AILeadService from "../../../../../data-access/services/aiLeadServices";

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: "#174736",
          color: "#ffffff",
          fontWeight: "bold",
        },
        body: {
          backgroundColor: "#ffffff",
          color: "#000000",
        },
      },
    },
  },
});

// const columns = ["Info Leads", "2023-10", "2023-11", "2023-12", "2024-01", "2024-02", "2024-03", "2024-04", "2024-05", "2024-06", "2024-07"];

// const rows = [
//   ["Call Back", 4, 2, 9, 17, 1, 1, 19, 9, 16, 8],
//   ["No Answer", 3, 13, 16, 9, 7, 19, 15, 2, 12, 1],
//   ["Not Interested", 2, 12, 17, 11, 16, 16, 4, 11, 10, 4],
//   ["Booked", 15, 20, 30, 20, 18, 2, 7, 8, 1, 8],
//   ["Interested - Call Back", 0, 1, 9, 6, 5, 13, 19, 16, 11, 11],
//   ["Not Contacted", 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
//   ["Grand Total", 24, 48, 81, 63, 47, 51, 64, 10, 7, 1],
// ];

const columns = [
  "Name",
  "Email",
  "Phone Number",
  "City",
  "Cause",
  "Date",
  "1st Attempt",
  "2nd Attempt",
  "Final Attempt",
  "Appointment Booked",
  "Notes",
];

const rows = [
  [
    "Alex Ofori",
    "oforialex393@gmail.com",
    "+16477028078",
    "Toronto Ontario",
    "Dental Implants",
    "January 31",
    "email, text, Call Booked",
    "—",
    "—",
    "Yes",
    "Booked on Feb 6th @5:00 pm",
  ],
  [
    "Prince Marvis",
    "mayorofontario724@gmail.com",
    "+14374106120",
    "Toronto",
    "Teeth Whitening",
    "January 31",
    "email, wcb Booked",
    "—",
    "—",
    "Yes",
    "Booked on Feb 6th @3:30 pm",
  ],
  [
    "Ian Joseph",
    "captainjoseph22@gmail.com",
    "+16472926676",
    "Brampton",
    "Dental Implants",
    "February 8",
    "NA, Booked",
    "NA, Lvm",
    "BOOKED ALREADY",
    "Yes",
    "—",
  ],
  [
    "Chris Hampton",
    "champton1475@gmail.com",
    "+14166240070",
    "Toronto",
    "Dental Implants",
    "February 11",
    "text, Email",
    "Booked on March 11th",
    "—",
    "Yes",
    "—",
  ],
  [
    "Jagdeo Ramoutar",
    "Jagdeo@tfnrealty.com",
    "+16479951580",
    "Woodbridge",
    "Dental Implants",
    "February 11",
    "ALREADY Booked",
    "Came on Feb 12th",
    "—",
    "Yes",
    "—",
  ],
  [
    "Ian Joseph",
    "captainjoseph22@gmail.com",
    "+16472926676",
    "Brampton",
    "Dental Implants",
    "February 11",
    "Booked",
    "—",
    "—",
    "Yes",
    "—",
  ],
  [
    "John Kodric",
    "johnkodric46@gmail.com",
    "+14168453327",
    "Brampton",
    "Dental Implants",
    "February 12",
    "NA, LVM, Email, TEXT",
    "Booked",
    "—",
    "Yes",
    "—",
  ],
  [
    "Anthony del Monte",
    "etnom_anthony@yahoo.com",
    "+16475173063",
    "Ontario",
    "Dental Implants",
    "February 13",
    "Email, text, call LVM",
    "Booked",
    "—",
    "Yes",
    "PT replied back to our email... I emailed him back and also called him",
  ],
  [
    "Rose",
    "lilak123@hotmail.com",
    "+16473305000",
    "Toronto",
    "Dental Implants",
    "February 14",
    "Text, Call Booked",
    "—",
    "—",
    "Yes",
    "Showed up / Aman",
  ],
  [
    "Raul",
    "rortega1467@gmail.com",
    "+16475546989",
    "Etobicoke",
    "Dental Implants",
    "February 19",
    "Email, text",
    "Booked",
    "—",
    "Yes",
    "—",
  ],
  [
    "Ferro Fausto",
    "ferrofausto@hotmail.com",
    "+14167888900",
    "Toronto",
    "Dental Implants",
    "February 19",
    "Email, text, Booked",
    "—",
    "—",
    "Yes",
    "—",
  ],
  [
    "Rocky Reno",
    "rockynparay@gmail.com",
    "+14166164307",
    "Toronto",
    "Dental Implants",
    "February 19",
    "Email, NA LVM",
    "Booked already",
    "—",
    "Yes",
    "—",
  ],
  [
    "Luis Angel Guzman Orellan",
    "ING.angel93@hotmail.com",
    "+16474511708",
    "Toronto",
    "Dental Implants",
    "February 19",
    "Email",
    "Booked already",
    "—",
    "Yes",
    "PT called on Feb 16th to book / Aman. Cancelled his appointment / Aman",
  ],
  [
    "Valette Williams",
    "valkay22@outlook.com",
    "+16479284640",
    "Toronto",
    "Dental Implants",
    "February 19",
    "Email",
    "Booked",
    "—",
    "Yes",
    "—",
  ],
  [
    "Graziano Villa",
    "villalandscape@gmail.com",
    "+14169363397",
    "Richmond Hill",
    "Dental Implants",
    "February 25",
    "Email, Text, Call",
    "Booked",
    "—",
    "Yes",
    "—",
  ],
  [
    "Mary Gachero",
    "sweetmary8@yahoo.com",
    "+14372277060",
    "Toronto",
    "Dental Implants",
    "February 25",
    "Booked",
    "—",
    "—",
    "Yes",
    "—",
  ],
  [
    "Adonis Foye",
    "adonisfoye@hotmail.com",
    "+16475610004",
    "Ontario",
    "Dental Implants",
    "February 27",
    "Booked",
    "—",
    "—",
    "Yes",
    "—",
  ],
  [
    "Veronica Torres",
    "torresfuentesmariaveronica@gmail.com",
    "+14372134209",
    "Toronto",
    "Dental Implants",
    "February 27",
    "Called, booked",
    "—",
    "—",
    "Yes",
    "—",
  ],
];

const InfoLeadsDetailsTable = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [leadData, setLeadData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const generatePopupData = (ln) => {
    return Array.from({ length: ln }, (_, i) => ({
      Name: "Alex Ofori",
      Email: "oforialex393@gmail.com",
      "Phone Number": "+16477028078",
      City: "Toronto Ontario",
      Cause: "Dental Implants",
      Date: "January 31",
      "1st Attempt": "email",
      "2nd Attempt": "text",
      "Final Attempt": "Call Booked",
      "Appointment Booked": "Yes",
      Notes: "Booked on Feb 6th @5:00 pm",
      "Office Note": "Escalated case",
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await AILeadService.getInfoLeadsAllDetails();
        const data = response?.data?.data || [];

        // Extract unique column names dynamically
        const uniqueColumns = Array.from(
          new Set(data.flatMap((row) => Object.keys(row)))
        );
        setColumns(uniqueColumns);
        setLeadData(data);
      } catch (err) {
        setError(err.message ?? "An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <TableContainer
        component={Paper}
        sx={{ overflowX: "auto" }}
        className="p-4"
      >
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell key={index} style={{ whiteSpace: "nowrap" }}>
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {/* {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => ( */}
          {/* <TableBody>
            {rows.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <TableCell
                    key={cellIndex}
                    sx={{ textAlign: cellIndex !== 0 ? "center" : "left" }}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {cell}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody> */}
          <TableBody>
            {leadData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  No data available
                </TableCell>
              </TableRow>
            ) : (
              leadData
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {columns.map((column, colIndex) => (
                      <TableCell
                        key={colIndex}
                        sx={{
                          textAlign: colIndex !== 0 ? "center" : "left",
                        }}
                      >
                        {row[column] !== null && row[column] !== undefined
                          ? row[column]
                          : "-"}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </TableContainer>
    </ThemeProvider>
  );
};

export default InfoLeadsDetailsTable;
