import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Modal,
  Fade,
  Box,
  Backdrop,
  TextField,
  MenuItem,
  TableSortLabel,
} from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import DetailsCallLeads from "./DetailsCallLeads";

const StickyTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  whiteSpace: "nowrap",
  position: "sticky",
  left: 0,
  // background: theme.palette.background.default,
  zIndex: 1000,
}));

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: "#174736 ",
          color: "#ffffff",
          fontWeight: "bold",
        },
        body: {
          backgroundColor: "#ffffff",
          color: "#000000",
        },
      },
    },
  },
});

const columns = [
  "YearMonth",
  "Call Status",
  "Start Time",
  "City",
  "Phone Number",
  "First-Time Caller",
  "Recording Url",
  "Status",
  "Office Note",
];

const rows = [
  //1-499
  [
    "2023-09",
    "Answered Call",
    "2023-09-30 10:39:02",
    "Montreal",
    "438-680-6319",
    "TRUE",
    "https://app.callrail.com/calls/CAL02b609e56180435cabf2f9c47c9e9777/recording/redirect?access_key=95686622a70edac3e0bc",
    "Paitent Booked",
    "Done",
  ],
  [
    "2023-09",
    "Answered Call",
    "2023-09-29 15:36:27",
    "Richmond Hill",
    "905-224-1000",
    "TRUE",
    "https://app.callrail.com/calls/CAL4853bfdca4ab443298240cb8043a3c6a/recording/redirect?access_key=fd2bec0143140598b6e3",
    "Paitent Booked",
    "Done",
  ],
  [
    "2023-09",
    "Answered Call",
    "2023-09-27 18:04:49",
    "Toronto",
    "647-573-1861",
    "TRUE",
    "https://app.callrail.com/calls/CALbcefbeb2ed8e460c93fb964930baa968/recording/redirect?access_key=bfb84e31a0d2593aa030",
    "Paitent Booked",
    "Done",
  ],
  [
    "2023-09",
    "Answered Call",
    "2023-09-27 14:55:01",
    "Toronto",
    "647-968-7725",
    "TRUE",
    "https://app.callrail.com/calls/CAL3990ccb2f34f4dd8b5dd2b27d801edcb/recording/redirect?access_key=47bce4a6c7f68756a4b1",
    "Paitent Booked",
    "Done",
  ],
  [
    "2023-09",
    "Answered Call",
    "2023-09-26 16:14:59",
    "Toronto",
    "437-987-1352",
    "FALSE",
    "https://app.callrail.com/calls/CAL1ff7451290844260917231edca76499f/recording/redirect?access_key=5c9932831fc6f557450c",
    "Paitent Booked",
    "Done",
  ],
  [
    "2023-09",
    "Answered Call",
    "2023-09-26 15:46:33",
    "Toronto",
    "647-323-6327",
    "TRUE",
    "https://app.callrail.com/calls/CAL8d9de61ebaaf467882fa10722f6608f0/recording/redirect?access_key=8f43f73a070c34e5fe64",
    "Paitent Booked",
    "Done",
  ],
  [
    "2023-09",
    "Answered Call",
    "2023-09-26 10:31:02",
    "Toronto",
    "416-801-4891",
    "TRUE",
    "https://app.callrail.com/calls/CALecb97a3dc79b4636aa3040b6dbb8332c/recording/redirect?access_key=db8058df1146b0cde124",
    "Paitent Booked",
    "Done",
  ],
  [
    "2023-09",
    "Answered Call",
    "2023-09-23 09:19:20",
    "Toronto",
    "647-774-3137",
    "TRUE",
    "https://app.callrail.com/calls/CAL8135294ac48f4e8ba08311c01fb75610/recording/redirect?access_key=51f19dc328836db9bb4c",
    "Paitent Booked",
    "Done",
  ],
  [
    "2023-09",
    "Voicemail",
    "2023-09-22 13:09:27",
    "Richmond Hill",
    "647-710-7670",
    "TRUE",
    "https://app.callrail.com/calls/CAL6698e16a92eb4f5a86f2cc3ebe1c575d/recording/redirect?access_key=9d6bbf373d12d53a44e0",
    "Paitent Booked",
    "Done",
  ],
  [
    "2023-09",
    "Answered Call",
    "2023-09-22 10:18:34",
    "Toronto",
    "416-566-8955",
    "FALSE",
    "https://app.callrail.com/calls/CAL5bc0a7188e0144d29ed550dc8834759a/recording/redirect?access_key=9301dab055381bd9d233",
    "Paitent Booked",
    "Done",
  ],
  [
    "2023-09",
    "Answered Call",
    "2023-09-19 13:12:08",
    "Toronto",
    "647-865-3669",
    "TRUE",
    "https://app.callrail.com/calls/CALef7cf295abd548d2a8f03aed4f75f555/recording/redirect?access_key=33d8fabdd4f433c96331",
    "Paitent Booked",
    "Done",
  ],
  [
    "2023-09",
    "Answered Call",
    "2023-09-16 09:32:45",
    "Toronto",
    "416-312-8487",
    "TRUE",
    "https://app.callrail.com/calls/CAL3dd63e90896c432fb6af7207b5706b86/recording/redirect?access_key=defffab713ffde5fa193",
    "Paitent Booked",
    "Done",
  ],
  [
    "2023-09",
    "Answered Call",
    "2023-09-16 09:14:30",
    "Toronto",
    "647-229-6262",
    "TRUE",
    "https://app.callrail.com/calls/CAL4330ee8002394d9b9ca085199dc823f0/recording/redirect?access_key=766ff1e3cd67f18b838d",
    "Paitent Booked",
    "Done",
  ],
  [
    "2023-09",
    "Answered Call",
    "2023-09-15 15:42:54",
    "Toronto",
    "647-936-0610",
    "TRUE",
    "https://app.callrail.com/calls/CAL027c43719d2f4aa08659a8f3b1bfc67f/recording/redirect?access_key=d9ba657e9ccb20407fd8",
    "Paitent Booked",
    "Done",
  ],
  [
    "2023-09",
    "Answered Call",
    "2023-09-15 12:04:04",
    "Toronto",
    "437-365-1116",
    "TRUE",
    "https://app.callrail.com/calls/CAL8b9da408927f46fabed7b222ef03beeb/recording/redirect?access_key=2c49e46e95c0c04a11a9",
    "Paitent Booked",
    "Done",
  ],
  //501 - 796
  [
    "2023-12",
    "Answered Call",
    "2023-12-15 14:54:34",
    "Toronto",
    "647-454-9546",
    "TRUE",
    "https://app.callrail.com/calls/CALe9cf6c6ec2c74043b96c6a1b0a71f3ab/recording/redirect?access_key=1eed2e653ccf5919a205",
    "Paitent Booked",
    "Done",
  ],
  [
    "2023-12",
    "Answered Call",
    "2023-12-15 14:20:56",
    "Toronto",
    "416-543-5824",
    "TRUE",
    "https://app.callrail.com/calls/CALd17d851c9b92479489c5a3e4e74dd769/recording/redirect?access_key=918e36a5fc2b2de11854",
    "Paitent Booked",
    "Done",
  ],
  [
    "2023-12",
    "Answered Call",
    "2023-12-15 13:31:19",
    "Toronto",
    "437-223-2256",
    "FALSE",
    "https://app.callrail.com/calls/CAL086e8a28dc074e0fbaefae6d6eedd2f5/recording/redirect?access_key=ece18c89cbc8d57fbfc8",
    "Paitent Booked",
    "Done",
  ],
  [
    "2023-12",
    "Answered Call",
    "2023-12-15 11:26:23",
    "Toronto",
    "647-892-8032",
    "TRUE",
    "https://app.callrail.com/calls/CAL502836350ff0486b994931e3c38309f6/recording/redirect?access_key=9bef07add4a16252c4ef",
    "Paitent Booked",
    "Done",
  ],
  [
    "2023-12",
    "Answered Call",
    "2023-12-15 11:17:51",
    "Toronto",
    "416-893-9373",
    "TRUE",
    "https://app.callrail.com/calls/CAL12ddc25143bd4db091f87cc8b7d72221/recording/redirect?access_key=eaea552ea6b9602fd175",
    "Paitent Booked",
    "Done",
  ],
  [
    "2023-12",
    "Answered Call",
    "2023-12-15 10:57:32",
    "St. Catharines-Thorold",
    "289-241-8958",
    "TRUE",
    "https://app.callrail.com/calls/CAL2fbe74b09dd249898864a12c741484b4/recording/redirect?access_key=03ccf2a3ea01b0059961",
    "Paitent Booked",
    "Done",
  ],
  [
    "2023-12",
    "Answered Call",
    "2023-12-15 10:37:53",
    "Toronto",
    "647-702-1196",
    "TRUE",
    "https://app.callrail.com/calls/CALce771edac59a4da4ada1aa46e414c088/recording/redirect?access_key=01ea39c5b59ac482cad9",
    "Paitent Booked",
    "Done",
  ],
  [
    "2023-12",
    "Voicemail",
    "2023-12-15 09:39:19",
    "Toronto",
    "647-975-0461",
    "TRUE",
    "https://app.callrail.com/calls/CAL69d2b5226be94faab9a4b2a388dc75b8/recording/redirect?access_key=bb2452d3ac9c4ba3313e",
    "Paitent Booked",
    "Done",
  ],
  [
    "2023-12",
    "Answered Call",
    "2023-12-14 16:10:43",
    "Toronto",
    "416-826-9797",
    "TRUE",
    "https://app.callrail.com/calls/CALd025988ebaba46069b0cc4aa496d0cd3/recording/redirect?access_key=7846dde811a0efb4497e",
    "Paitent Booked",
    "Done",
  ],
  [
    "2023-12",
    "Answered Call",
    "2023-12-14 14:01:33",
    "Toronto",
    "647-232-7326",
    "TRUE",
    "https://app.callrail.com/calls/CAL1290a76aee444752b951280b4f7786a6/recording/redirect?access_key=9cc7ed79d6397af3b8c0",
    "Paitent Booked",
    "Done",
  ],
  [
    "2023-12",
    "Answered Call",
    "2023-12-14 09:42:09",
    "Toronto",
    "416-526-2974",
    "TRUE",
    "https://app.callrail.com/calls/CALf8cf1687ae494d6da2d281293d1941e2/recording/redirect?access_key=372a993bba3a03dd23dd",
    "Paitent Booked",
    "Done",
  ],
  [
    "2023-12",
    "Answered Call",
    "2023-12-14 07:32:54",
    "Toronto",
    "647-703-6180",
    "FALSE",
    "https://app.callrail.com/calls/CAL94295cb76714467fa0757d231907c26d/recording/redirect?access_key=b21e256e682937c4cc2e",
    "Paitent Booked",
    "Done",
  ],
  [
    "2023-12",
    "Answered Call",
    "2023-12-14 07:30:44",
    "Toronto",
    "647-703-6180",
    "TRUE",
    "https://app.callrail.com/calls/CAL35d86556d1be4dc5a83e639c5f10bfb7/recording/redirect?access_key=451b1079333e9a16d738",
    "Paitent Booked",
    "Done",
  ],
  [
    "2023-12",
    "Answered Call",
    "2023-12-13 12:03:09",
    "Toronto",
    "416-795-8242",
    "FALSE",
    "https://app.callrail.com/calls/CAL1ae45fd602544fd1bfe1478a6e40eb37/recording/redirect?access_key=71c5634cc37ea5251d80",
    "Paitent Booked",
    "Done",
  ],
  [
    "2023-12",
    "Answered Call",
    "2023-12-12 18:01:05",
    "Toronto",
    "647-234-2894",
    "TRUE",
    "https://app.callrail.com/calls/CAL29637b5883fa4e9c98385731259a14b6/recording/redirect?access_key=5bc2425bd27ed67606e1",
    "Paitent Booked",
    "Done",
  ],
  [
    "2023-12",
    "Answered Call",
    "2023-12-09 09:54:13",
    "Toronto",
    "647-268-4429",
    "TRUE",
    "https://app.callrail.com/calls/CAL6a3b5c9f1fdc4982aceb1845e26cc617/recording/redirect?access_key=fd4306095f27cb0cd247",
    "Paitent Booked",
    "Done",
  ],
  [
    "2023-12",
    "Answered Call",
    "2023-12-07 16:27:28",
    "Halifax",
    "902-943-0118",
    "TRUE",
    "https://app.callrail.com/calls/CAL2f8abe32ba8a481299f39d567b03d718/recording/redirect?access_key=ceccae40e1406b073b81",
    "Paitent Booked",
    "Done",
  ],
  [
    "2023-12",
    "Answered Call",
    "2023-12-06 13:05:26",
    "Toronto",
    "437-288-5498",
    "TRUE",
    "https://app.callrail.com/calls/CAL8967d9d67ef141cd8cf6415d7350ba5a/recording/redirect?access_key=b09f329b1b87f72eaa80",
    "Paitent Booked",
    "Done",
  ],
  [
    "2023-12",
    "Answered Call",
    "2023-12-06 11:40:57",
    "Toronto",
    "437-232-3194",
    "TRUE",
    "https://app.callrail.com/calls/CALe4900493a9c44b795b0b94b3fa1d0ae4/recording/redirect?access_key=e59d8b98d23e643b3f87",
    "Paitent Booked",
    "Done",
  ],
  [
    "2023-12",
    "Answered Call",
    "2023-12-06 09:46:43",
    "Toronto",
    "437-232-3194",
    "TRUE",
    "https://app.callrail.com/calls/CALbc2ed6db1e9a4868a289104100cc1ba5/recording/redirect?access_key=dd22a02a4c3f66a8244a",
    "Paitent Booked",
    "Done",
  ],
  [
    "2024-02",
    "Answered Call",
    "2024-02-20 11:12:16",
    "Montreal",
    "416-670-6098",
    "FALSE",
    "https://app.callrail.com/calls/CALf0e0a9a7b2d444a797824cde0d81b245/recording/redirect?access_key=36dc64aae1e9edbaa0df",
    "Patient Booked",
    "Done",
  ],
  [
    "2024-02",
    "Answered Call",
    "2024-02-20 10:50:21",
    "Richmond Hill",
    "416-717-0507",
    "FALSE",
    "https://app.callrail.com/calls/CALbe2a97e4ff19470dbd4c0216407f0af5/recording/redirect?access_key=a779cf4a215b708685b0",
    "Patient Booked",
    "Done",
  ],
  [
    "2024-02",
    "Answered Call",
    "2024-02-16 17:18:30",
    "Toronto",
    "416-305-5005",
    "TRUE",
    "https://app.callrail.com/calls/CAL8e7a29bd7b914052b2e53b8ddccf3c6f/recording/redirect?access_key=cc28696a2edba3abc7fa",
    "Patient Booked",
    "Done",
  ],
  [
    "2024-02",
    "Answered Call",
    "2024-02-16 13:09:16",
    "Toronto",
    "647-989-8801",
    "TRUE",
    "https://app.callrail.com/calls/CAL77617a9d1244413baf4c89d01e76b0ba/recording/redirect?access_key=55778556da81a1660a41",
    "Patient Booked",
    "Done",
  ],
  [
    "2024-02",
    "Answered Call",
    "2024-02-16 10:31:29",
    "Toronto",
    "647-290-7341",
    "TRUE",
    "https://app.callrail.com/calls/CALb2a77587550944419f46674b0826e7df/recording/redirect?access_key=0daea5c2c312aec51925",
    "Patient Booked",
    "Done",
  ],
  [
    "2024-02",
    "Answered Call",
    "2024-02-16 10:13:36",
    "Toronto",
    "647-283-5048",
    "FALSE",
    "https://app.callrail.com/calls/CALee2e0ec387eb4ce2b311fdf490127109/recording/redirect?access_key=ba20bea2de014cfd7d4b",
    "Patient Booked",
    "Done",
  ],
  [
    "2024-02",
    "Answered Call",
    "2024-02-14 16:34:35",
    "Toronto",
    "905-737-2714",
    "TRUE",
    "https://app.callrail.com/calls/CAL67172e90c3d344d0af113792fc9a6208/recording/redirect?access_key=832398bbe762de2c1724",
    "Patient Booked",
    "Done",
  ],
  [
    "2024-02",
    "Answered Call",
    "2024-02-14 13:25:09",
    "Toronto",
    "647-838-8260",
    "FALSE",
    "https://app.callrail.com/calls/CALd396fcd7f8604818b3c912eae46d6735/recording/redirect?access_key=27eb0cf6a2180f3bb9f3",
    "Patient Booked",
    "Done",
  ],
  [
    "2024-02",
    "Answered Call",
    "2024-02-14 12:11:23",
    "Toronto",
    "647-457-9166",
    "TRUE",
    "https://app.callrail.com/calls/CAL6f36b2bfa8d8441eb4763039bdc7dad4/recording/redirect?access_key=8d7e7a5230911795fe14",
    "Patient Booked",
    "Done",
  ],
  [
    "2024-02",
    "Answered Call",
    "2024-02-10 17:11:18",
    "Toronto",
    "647-992-3050",
    "TRUE",
    "https://app.callrail.com/calls/CAL676ec239cd99440ead471d02cb1b5cb5/recording/redirect?access_key=f51a80eaf9b4eb95858e",
    "Patient Booked",
    "Done",
  ],
  [
    "2024-02",
    "Answered Call",
    "2024-02-08 13:17:54",
    "Toronto",
    "317-684-9478",
    "TRUE",
    "https://app.callrail.com/calls/CALd97f0564704840e5ad5594d1158517e0/recording/redirect?access_key=a4a734e7ea055ff1b588",
    "Patient Booked",
    "Done",
  ],
  [
    "2024-02",
    "Answered Call",
    "2024-02-08 13:12:46",
    "Toronto",
    "647-293-4241",
    "TRUE",
    "https://app.callrail.com/calls/CAL1d2bfcb2b34a4321864e8354f82884e6/recording/redirect?access_key=9c255c27338f1ee08272",
    "Patient Booked",
    "Done",
  ],
];

const CallLeadsDetailsTable = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [date, setDate] = useState("2024-09");
  const [cellValue, setCellValue] = useState("Missed call");
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });
  const [filters, setFilters] = useState(Array(columns.length).fill(""));
  const [editableRows, setEditableRows] = useState(
    rows.map((row) => row.map((cell) => (cell === "—" ? "" : cell))) // Initialize editable cells
  );

  const handleRowChange = (rowIndex, columnIndex, value) => {
    const updatedRows = [...editableRows];
    updatedRows[rowIndex][columnIndex] = value;
    setEditableRows(updatedRows);
  };

  const handleSave = () => {
    // Here you can save the data to the backend or update the rows state.
    console.log("Saved rows: ", editableRows);
  };

  const handleSort = (columnIndex) => {
    setSortConfig((prev) => ({
      key: columnIndex,
      direction:
        prev.key === columnIndex && prev.direction === "asc" ? "desc" : "asc",
    }));
  };

  const handleFilterChange = (index, value) => {
    const updatedFilters = [...filters];
    updatedFilters[index] = value;
    setFilters(updatedFilters);
  };

  const filteredRows = rows.filter((row) =>
    row.every((cell, index) =>
      cell.toString().toLowerCase().includes(filters[index].toLowerCase())
    )
  );

  const sortedRows = [...filteredRows].sort((a, b) => {
    const key = sortConfig.key;
    if (key === "") return 0;

    const aValue = a[key] || "";
    const bValue = b[key] || "";

    if (!isNaN(aValue) && !isNaN(bValue)) {
      return sortConfig.direction === "asc" ? aValue - bValue : bValue - aValue;
    } else {
      return sortConfig.direction === "asc"
        ? aValue.toString().localeCompare(bValue.toString())
        : bValue.toString().localeCompare(aValue.toString());
    }
  });

  const [openDetailsCallLeadsModal, setOpenDetailsCallLeadsModal] =
    useState(false);
  const handleDetailsCallLeadsModalClose = () => {
    setOpenDetailsCallLeadsModal(false);
  };

  const handleRowClick = (col, lead_type) => {
    setDate(col);
    setCellValue(lead_type);
    setOpenDetailsCallLeadsModal(true);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "auto",
    width: "80%",
    bgcolor: "background.paper",
    border: "2px solid #184332",
    boxShadow: 40,
    p: 4,
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <ThemeProvider theme={theme}>
      <TableContainer
        component={Paper}
        sx={{ overflowX: "auto" }}
        // className="p-4"
      >
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column, index) =>
                index === 0 ? (
                  <StickyTableCell
                    key={index}
                    sx={{
                      textAlign: "left",
                      whiteSpace: "nowrap",
                      cursor: "pointer",
                    }}
                    onClick={() => handleSort(index)}
                  >
                    <TableSortLabel
                      active={sortConfig.key === index}
                      direction={
                        sortConfig.key === index ? sortConfig.direction : "asc"
                      }
                      sx={{
                        color: "white", // Make the text color white
                        "&:hover": {
                          color: "white", // Ensure the color stays white when hovered
                        },
                      }}
                    >
                      {column}
                    </TableSortLabel>
                  </StickyTableCell>
                ) : (
                  <TableCell
                    key={index}
                    sx={{
                      textAlign: "left",
                      whiteSpace: "nowrap",
                      cursor: "pointer",
                    }}
                    onClick={() => handleSort(index)}
                  >
                    <TableSortLabel
                      active={sortConfig.key === index}
                      direction={
                        sortConfig.key === index ? sortConfig.direction : "asc"
                      }
                      sx={{
                        color: "white", // Make the text color white
                        "&:hover": {
                          color: "white", // Ensure the color stays white when hovered
                        },
                      }}
                    >
                      {column}
                    </TableSortLabel>
                  </TableCell>
                )
              )}
            </TableRow>
            <TableRow>
              {columns.map((_, index) => (
                <TableCell key={index}>
                  <TextField
                    size="small"
                    variant="outlined"
                    placeholder="Filter..."
                    value={filters[index]}
                    onChange={(e) => handleFilterChange(index, e.target.value)}
                    // sx={{ width: "100%", color: "white" }}
                    sx={{
                      "& .MuiInputBase-input": {
                        color: "white", // Text color for the input field
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "white", // Border color for the input field
                        },
                        "&:hover fieldset": {
                          borderColor: "white", // Border color on hover
                        },
                      },
                    }}
                  />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRows.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {row.map((cell, cellIndex) =>
                  cellIndex === 0 ? (
                    <StickyTableCell key={cellIndex} sx={{ textAlign: "left" }}>
                      {cell}
                    </StickyTableCell>
                  ) : // : (
                  //   <TableCell key={cellIndex} sx={{ textAlign: "left" }}>
                  //     {cell}
                  //   </TableCell>
                  // )
                  cellIndex >= 7 ? (
                    <TableCell key={cellIndex}>
                      <TextField
                        size="small"
                        variant="outlined"
                        value={editableRows[rowIndex][cellIndex]}
                        onChange={(e) =>
                          handleRowChange(rowIndex, cellIndex, e.target.value)
                        }
                      />
                    </TableCell>
                  ) : (
                    <TableCell
                      key={cellIndex}
                      sx={{ textAlign: "left" }}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {cell}
                    </TableCell>
                  )
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </TableContainer>
    </ThemeProvider>
  );
};

export default CallLeadsDetailsTable;
