// import React from "react";
// import ReactApexChart from "react-apexcharts";

// const chartCategories = [
//   "2023-10", "2023-11", "2023-12", "2024-01", "2024-02",
//   "2024-03", "2024-04", "2024-05", "2024-06", "2024-07"
// ];

// // const dataSets = [
// //   {
// //     name: "Abandon Call",
// //     currentData: [13, 9, 17, 14, 13, 14, 8, 13, 2, 6],
// //     lastYearData: [9, 10, 20, 12, 15, 16, 10, 14, 5, 9]
// //   },
// //   {
// //     name: "Missed Call",
// //     currentData: [22, 15, 43, 54, 35, 19, 27, 31, 15, 12],
// //     lastYearData: [25, 17, 45, 50, 38, 22, 30, 33, 18, 14]
// //   },
// //   {
// //     name: "New Patient Opportunity",
// //     currentData: [14, 10, 10, 12, 12, 11, 20, 14, 23, 60],
// //     lastYearData: [16, 12, 12, 14, 14, 13, 22, 16, 25, 65]
// //   },
// //   {
// //     name: "Patient Booked",
// //     currentData: [33, 11, 31, 23, 30, 46, 21, 25, 23, 8],
// //     lastYearData: [36, 14, 34, 26, 33, 50, 24, 28, 26, 10]
// //   },
// //   {
// //     name: "Question",
// //     currentData: [77, 84, 77, 58, 42, 53, 38, 53, 29, 44],
// //     lastYearData: [80, 86, 79, 60, 45, 55, 40, 56, 32, 48]
// //   }
// // ];

// const dataSets = [
//     {
//       name: "Abandon Call",
//       currentData: [13, 9, 17, 14, 13, 14, 8, 13, 2, 6],
//       lastYearData: [7, 12, 10, 18, 11, 20, 15, 22, 17, 25]
//     },
//     {
//       name: "Missed Call",
//       currentData: [22, 15, 43, 54, 35, 19, 27, 31, 15, 12],
//       lastYearData: [20, 18, 40, 50, 32, 25, 35, 42, 28, 38]
//     },
//     {
//       name: "New Patient Opportunity",
//       currentData: [14, 10, 10, 12, 12, 11, 20, 14, 23, 60],
//       lastYearData: [12, 15, 13, 18, 14, 20, 22, 30, 35, 65]
//     },
//     {
//       name: "Patient Booked",
//       currentData: [33, 11, 31, 23, 30, 46, 21, 25, 23, 8],
//       lastYearData: [28, 16, 27, 35, 32, 50, 29, 40, 37, 45]
//     },
//     {
//       name: "Question",
//       currentData: [77, 84, 77, 58, 42, 53, 38, 53, 29, 44],
//       lastYearData: [70, 78, 74, 65, 50, 60, 48, 58, 40, 55]
//     }
//   ];

// const CallComparisonChart = () => {
//   return (
//     <div>
//       {dataSets.map(({ name, currentData, lastYearData }) => {
//         const chartOptions = {
//           chart: {
//             id: `${name.toLowerCase().replace(/ /g, '-')}-chart`,
//             type: "line",
//             zoom: { enabled: false },
//             toolbar: { show: false },
//           },
//           xaxis: {
//             categories: chartCategories,
//             title: { text: "Months" },
//           },
//           yaxis: { title: { text: "Number of Calls / Interactions" } },
//           stroke: { curve: "smooth" },
//           title: {
//             text: `${name} Comparison: Current Year vs Last Year`,
//             align: "center",
//           },
//           colors: ["#008FFB", "#FF4560"],
//           legend: { position: "top" },
//         };

//         const series = [
//           { name: `${name} (2023-2024)`, data: currentData },
//           { name: `${name} (2022-2023)`, data: lastYearData }
//         ];

//         return (
//           <div key={name} style={{ marginBottom: "20px" }}>
//             <ReactApexChart options={chartOptions} series={series} type="line" height={400} />
//           </div>
//         );
//       })}
//     </div>
//   );
// };

// export default CallComparisonChart;

import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const chartCategories = [
  "2023-10",
  "2023-11",
  "2023-12",
  "2024-01",
  "2024-02",
  "2024-03",
  "2024-04",
  "2024-05",
  "2024-06",
  "2024-07",
];

const dataSets = [
  {
    name: "Abandon Call",
    currentData: [13, 9, 17, 14, 13, 14, 8, 13, 2, 6],
    lastYearData: [7, 12, 10, 18, 11, 20, 15, 22, 17, 25],
  },
  {
    name: "Missed Call",
    currentData: [22, 15, 43, 54, 35, 19, 27, 31, 15, 12],
    lastYearData: [20, 18, 40, 50, 32, 25, 35, 42, 28, 38],
  },
  {
    name: "New Patient Opportunity",
    currentData: [14, 10, 10, 12, 12, 11, 20, 14, 23, 60],
    lastYearData: [12, 15, 13, 18, 14, 20, 22, 30, 35, 65],
  },
  {
    name: "Patient Booked",
    currentData: [33, 11, 31, 23, 30, 46, 21, 25, 23, 8],
    lastYearData: [28, 16, 27, 35, 32, 50, 29, 40, 37, 45],
  },
  {
    name: "Question",
    currentData: [77, 84, 77, 58, 42, 53, 38, 53, 29, 44],
    lastYearData: [70, 78, 74, 65, 50, 60, 48, 58, 40, 55],
  },
];

const CallComparisonChart = () => {
  const [selectedChart, setSelectedChart] = useState(dataSets[0]);

  const handleChange = (event) => {
    const selected = dataSets.find(
      (dataset) => dataset.name === event.target.value
    );
    setSelectedChart(selected);
  };

  const chartOptions = {
    chart: {
      id: `${selectedChart.name.toLowerCase().replace(/ /g, "-")}-chart`,
      type: "line",
      zoom: { enabled: false },
      toolbar: { show: false },
    },
    xaxis: {
      categories: chartCategories,
      title: { text: "Months" },
    },
    yaxis: { title: { text: "Number of Calls / Interactions" } },
    stroke: {
      width: [5, 5],
      curve: "smooth",
      dashArray: [0, 2],
    },
    title: {
      text: `${selectedChart.name} Comparison: Current Year vs Last Year`,
      align: "center",
    },
    colors: ["#0D3B2E", "#FF4560"],
    legend: { position: "top" },
  };

  const series = [
    {
      name: `${selectedChart.name} (2023-2024)`,
      data: selectedChart.currentData,
    },
    {
      name: `${selectedChart.name} (2022-2023)`,
      data: selectedChart.lastYearData,
    },
  ];

  return (
    <div className="mt-6">
      <div>
        <select
          onChange={handleChange}
          value={selectedChart.name}
          style={{ marginBottom: "20px", fontSize: "16px" }}
          className="bg-[#174736] text-white rounded-md py-2 px-2"
        >
          {dataSets.map(({ name }) => (
            <option key={name} value={name}>
              {name}
            </option>
          ))}
        </select>
      </div>

      <ReactApexChart
        options={chartOptions}
        series={series}
        type="line"
        height={400}
      />
    </div>
  );
};

export default CallComparisonChart;
