import React, { useState } from "react";
import NavAndTopBar from "../../../shared/layouts/NavAndTopBar";
import SumTable from "./SumTable";
import SumTableCharts from "./SumTableCharts";
import CallLeads from "./CallLeads";
import InfoLeads from "./InfoLeads";
import GoogleRanking from "./GoogleRanking";
import SocialMediaReportFacebook from "./SocialMediaReportFacebook";
import SocialMediaReportInstagram from "./SocialMediaReportInstagram";
import SocialMediaPostingCalendar from "./SocialMediaPostingCalendar";
import GoogleMapRanking from "./GoogleMapRanking";
import GMBReports from "./GMBReport";
import CompanyLogoAndMoto from "./CompanyLogoAndMoto";
import CallLeadsChart from "./CallLeadsChart";
import CallLeadsAbandonCallChart from "./CallLeadsAbandonCallChart";
import InfoLeadsChart from "./InfoLeadsChart";
import OpportunityBarChart from "./OppurtunityVsBookedChart";
import CallLeadsTable from "./CallLeadsTable";

const dataSets = [
  {
    name: "Call Leads",
    currentData: [13, 9, 17, 14, 13, 14, 8, 13, 2, 6],
    lastYearData: [7, 12, 10, 18, 11, 20, 15, 22, 17, 25],
  },
  {
    name: "Info Leads",
    currentData: [22, 15, 43, 54, 35, 19, 27, 31, 15, 12],
    lastYearData: [20, 18, 40, 50, 32, 25, 35, 42, 28, 38],
  },
];

export default function AILeadReportDashboard() {
  const [selectedChart, setSelectedChart] = useState(dataSets[0]);

  const handleChange = (event) => {
    const selected = dataSets.find(
      (dataset) => dataset.name === event.target.value
    );
    setSelectedChart(selected);
  };

  return (
    <NavAndTopBar>
      <div className="bg-white p-4 flex flex-col gap-8">
        {/* {leadType === "leadOne" && <CallLeads />} */}
        {/* {leadType === "leadTwo" && <InfoLeads />} */}
        <div>
          <CompanyLogoAndMoto />
        </div>
        <div>
          <div className="flex justify-center">
            <p className="golden-txt  font-bold text-2xl my-2">
              AI Lead Report Summary
            </p>
          </div>
          <div className="flex flex-col gap-8">
            <SumTable />
            {/* <SumTableCharts /> */}
            <OpportunityBarChart />
            <div>
              <select
                onChange={handleChange}
                value={selectedChart.name}
                style={{ fontSize: "16px" }}
                className="bg-[#174736] text-white rounded-md py-2 px-2"
              >
                {dataSets.map(({ name }) => (
                  <option key={name} value={name}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              {selectedChart.name === "Info Leads" ? (
                <div>
                  <InfoLeads />
                  <InfoLeadsChart />
                </div>
              ) : (
                <div>
                  <CallLeads />
                  <CallLeadsAbandonCallChart />
                </div>
              )}
            </div>
            {/* <CallLeadsTable /> */}
            {/* <CallLeadsChart /> */}
            {/* <CallLeadsAbandonCallChart /> */}
            {/* <InfoLeadsChart /> */}
          </div>
        </div>
        <div>
          <div className="flex justify-center">
            <p className="golden-txt font-bold text-2xl my-2">
              Website Ranking Report
            </p>
          </div>
          <p className="golden-txt font-bold text-lg my-2">Google Ranking</p>
          <GoogleRanking />
          <p className="golden-txt font-bold text-lg my-2">
            Google Map Ranking
          </p>
          <GoogleMapRanking />
          <p className="golden-txt font-bold text-lg my-2">GMB Report</p>
          <GMBReports />
        </div>
        <div>
          <div className="flex justify-center">
            <p className="golden-txt font-bold text-2xl my-2">
              Social Media Report
            </p>
          </div>
          <div>
            <p className="golden-txt font-bold text-lg my-2">FaceBook</p>
            <SocialMediaReportFacebook />
            <p className="golden-txt font-bold text-lg my-2 mt-8">Instragram</p>
            <SocialMediaReportInstagram />
          </div>
        </div>
        <div>
          <div className="flex justify-center">
            <p className="golden-txt font-bold text-2xl my-2">
              Social Media Posting Calendar
            </p>
          </div>
          <SocialMediaPostingCalendar />
        </div>
      </div>
    </NavAndTopBar>
  );
}
