import React from "react";
import ReactApexChart from "react-apexcharts";

const months = [
  "2023-10",
  "2023-11",
  "2023-12",
  "2024-01",
  "2024-02",
  "2024-03",
  "2024-04",
  "2024-05",
  "2024-06",
  "2024-07",
];

const opportunities = [71, 69, 109, 92, 111, 115, 73, 49, 53, 69];
const booked = [54, 31, 61, 43, 48, 48, 28, 33, 24, 23];

// Function to convert values to percentages
const calculatePercentages = (data) => {
  const numericData = data.map(Number); // Ensure all values are numbers
  const total = numericData.reduce((sum, val) => sum + val, 0) || 1; // Avoid division by zero
  return numericData.map((val) => parseFloat(((val / total) * 100).toFixed(2))); // Return as numbers
};

const opportunityPercentages = calculatePercentages(opportunities);
const bookedPercentages = calculatePercentages(booked);

const PieCharts = () => {
  return (
    <div className="flex flex-col md:flex-row gap-8 p-5">
      {/* Total Opportunities Pie Chart */}
      <div className="w-full md:w-1/2">
        <h2 className="text-center font-bold text-lg mb-4">
          Total Opportunities
        </h2>
        <ReactApexChart
          options={{
            labels: months,
            legend: { position: "bottom" },
          }}
          series={opportunityPercentages}
          type="pie"
          height={350}
        />
      </div>

      {/* Total Booked Pie Chart */}
      <div className="w-full md:w-1/2">
        <h2 className="text-center font-bold text-lg mb-4">Total Booked</h2>
        <ReactApexChart
          options={{
            labels: months,
            legend: { position: "bottom" },
          }}
          series={bookedPercentages}
          type="pie"
          height={350}
        />
      </div>
    </div>
  );
};

export default PieCharts;
