import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import AILeadService from "../../../../data-access/services/aiLeadServices";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: "#174736 ",
          color: "#ffffff",
          fontWeight: "bold",
        },
        body: {
          backgroundColor: "#ffffff",
          color: "#000000",
        },
      },
    },
  },
});

export default function GoogleRanking() {
  const [leadData, setLeadData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "" });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await AILeadService.getGoogleSearchRankings();
        const apiData = response?.data?.data || [];
        if (apiData.length > 0) {
          // Extract columns dynamically
          const dynamicColumns = Object.keys(apiData[0]).map((key) => ({
            id: key,
            label: key === "Top queries" ? "Keywords" : key,
          }));
          setColumns(dynamicColumns);
          setLeadData(apiData);
        }
      } catch (err) {
        setError(err.message || "An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });

    const sortedData = [...leadData].sort((a, b) => {
      if (a[key] === null || a[key] === undefined) return 1;
      if (b[key] === null || b[key] === undefined) return -1;
      if (direction === "ascending") {
        return a[key] > b[key] ? 1 : -1;
      }
      return a[key] < b[key] ? 1 : -1;
    });
    setLeadData(sortedData);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="h-[450px] overflow-y-scroll">
      <ThemeProvider theme={theme}>
        <TableContainer
          component={Paper}
          sx={{ overflowX: "auto" }}
          className="p-4"
        >
          <Table sx={{ minWidth: 650 }} aria-label="Google Ranking Table">
            <TableHead className="bg-[#0d271e]">
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    key={column.id}
                    sx={{ textAlign: index !== 0 ? "center" : "left" }}
                    style={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                    onClick={() => handleSort(column.id)}
                  >
                    {column.label}
                    {sortConfig.key === column.id
                      ? sortConfig.direction === "ascending"
                        ? " ↑"
                        : " ↓"
                      : null}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* {leadData
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {columns.map((column, cellIndex) => (
                      <TableCell
                        key={column.id}
                        style={{ backgroundColor: "white" }}
                        sx={{ textAlign: cellIndex !== 0 ? "center" : "left" }}
                      >
                        {row[column.id] ?? "-"}
                      </TableCell>
                    ))}
                  </TableRow>
                ))} */}
              {leadData.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {columns.map((column, cellIndex) => {
                    const cellValue = row[column.id]; // Current column value
                    const prevCellValue =
                      cellIndex > 0 ? row[columns[cellIndex - 1].id] : null; // Previous column value

                    return (
                      <TableCell
                        key={column.id}
                        style={{ backgroundColor: "white" }}
                        sx={{ textAlign: cellIndex !== 0 ? "center" : "left" }}
                      >
                        {cellValue ?? "-"}

                        {/* Show icons for numeric columns (excluding first column) */}
                        {cellIndex > 0 &&
                          typeof cellValue === "number" &&
                          typeof prevCellValue === "number" &&
                          (cellValue > prevCellValue ? (
                            <ArrowDownward
                              fontSize="small"
                              color="error"
                              sx={{ ml: 0.5 }}
                            />
                          ) : cellValue < prevCellValue ? (
                            <ArrowUpward
                              fontSize="small"
                              color="success"
                              sx={{ ml: 0.5 }}
                            />
                          ) : null)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {/* <TablePagination
        component="div"
        count={leadData.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
      /> */}
        </TableContainer>
      </ThemeProvider>
    </div>
  );
}
