import { createContext, useContext, useEffect, useState } from "react";

const LeadGenerationContext = createContext(undefined);

const LeadGenerationProvider = ({ children }) => {
  const [isCallLeadsLoading, setIsIsCallLeadsLoading] = useState(false);
  const [isCallInfoLoading, setIsIsInfoLeadsLoading] = useState(false);
  const [callLeadsData, setCallLeadsData] = useState(undefined);
  const [infoLeadsData, setInfoLeadsData] = useState(undefined);
  const [googleRankingKeywords, setGoogleRankingKeywords] = useState([]);
  const [googleMapRankingKeywords, setGoogleMapRankingKeywords] = useState([]);

  useEffect(() => {console.log("googleRankingKeywords : ",googleRankingKeywords, "/n", "googleMapRankingKeywords : ", googleMapRankingKeywords)}, [isCallLeadsLoading, isCallInfoLoading, googleRankingKeywords, googleMapRankingKeywords]);

  return (
    <LeadGenerationContext.Provider
      value={{
        isCallLeadsLoading,
        setIsIsCallLeadsLoading,
        isCallInfoLoading,
        setIsIsInfoLeadsLoading,
        callLeadsData,
        setCallLeadsData,
        infoLeadsData,
        setInfoLeadsData,
        googleRankingKeywords,
        setGoogleRankingKeywords,
        googleMapRankingKeywords,
        setGoogleMapRankingKeywords
      }}
    >
      {children}
    </LeadGenerationContext.Provider>
  );
};

export { LeadGenerationProvider, LeadGenerationContext };
