import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: "#174736",
          color: "#ffffff",
          fontWeight: "bold",
        },
        body: {
          backgroundColor: "#ffffff",
          color: "#000000",
        },
      },
    },
  },
});

export default function SocialMediaPostingCalendar() {
  const data = [
    {
      platform: "Instagram",
      color: "#C51162", // Purple
      posts: {
        "02 Sep": "Posted",
        "04 Sep": "Posted",
        "06 Sep": "Posted",
        "09 Sep": "Posted",
        "11 Sep": "Posted",
        "13 Sep": "Canceled",
        "16 Sep": "Posted",
        "18 Sep": "Posted",
        "21 Sep": "Posted",
        "23 Sep": "Posted",
        "26 Sep": "Posted",
        "30 Sep": "Posted",
      },
    },
    {
      platform: "Facebook",
      color: "#2962FF", // Blue
      posts: {
        "02 Sep": "Posted",
        "04 Sep": "Posted",
        "06 Sep": "Posted",
        "09 Sep": "Posted",
        "11 Sep": "Posted",
        "13 Sep": "Canceled",
        "16 Sep": "Posted",
        "18 Sep": "Posted",
        "21 Sep": "Posted",
        "23 Sep": "Posted",
        "26 Sep": "Posted",
        "30 Sep": "Posted",
      },
    },
    {
      platform: "LinkedIn",
      color: "#00796B", // Teal
      posts: {
        "02 Sep": "Posted",
        "04 Sep": "Posted",
        "06 Sep": "Posted",
        "09 Sep": "Posted",
        "11 Sep": "Posted",
        "13 Sep": "Canceled",
        "16 Sep": "Posted",
        "18 Sep": "Posted",
        "21 Sep": "Posted",
        "23 Sep": "Posted",
        "26 Sep": "Posted",
        "30 Sep": "Posted",
      },
    },
    {
      platform: "X",
      color: "#000000", // Black
      posts: {
        "02 Sep": "Posted",
        "04 Sep": "Posted",
        "06 Sep": "Posted",
        "09 Sep": "Posted",
        "11 Sep": "Posted",
        "13 Sep": "Canceled",
        "16 Sep": "Posted",
        "18 Sep": "Posted",
        "21 Sep": "Posted",
        "23 Sep": "Posted",
        "26 Sep": "Posted",
        "30 Sep": "Posted",
      },
    },
    {
      platform: "Pinterest",
      color: "#D50000", // Red
      posts: {
        "02 Sep": "Posted",
        "04 Sep": "Posted",
        "06 Sep": "Posted",
        "09 Sep": "Posted",
        "11 Sep": "Posted",
        "13 Sep": "Canceled",
        "16 Sep": "Posted",
        "18 Sep": "Posted",
        "21 Sep": "Posted",
        "23 Sep": "Posted",
        "26 Sep": "Posted",
        "30 Sep": "Posted",
      },
    },
    {
      platform: "TikTok",
      color: "#1A237E", // Dark Blue
      posts: {
        "02 Sep": "Posted",
        "04 Sep": "Posted",
        "06 Sep": "Posted",
        "09 Sep": "Posted",
        "11 Sep": "Posted",
        "13 Sep": "Canceled",
        "16 Sep": "Posted",
        "18 Sep": "Posted",
        "21 Sep": "Posted",
        "23 Sep": "Posted",
        "26 Sep": "Posted",
        "30 Sep": "Posted",
      },
    },
  ];

  const columns = [
    "Media Name",
    "02 Sep",
    "04 Sep",
    "06 Sep",
    "09 Sep",
    "11 Sep",
    "13 Sep",
    "16 Sep",
    "18 Sep",
    "21 Sep",
    "23 Sep",
    "26 Sep",
    "30 Sep",
  ];

  return (
    <ThemeProvider theme={theme}>
      <TableContainer
        component={Paper}
        sx={{ overflowX: "auto" }}
        className="p-4"
      >
        <Table sx={{ minWidth: 650 }} aria-label="social media schedule">
          <TableHead className="bg-[#0D3B2E]">
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  sx={{ textAlign: index !== 0 ? "center" : "left" }}
                  style={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                >
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>
                <TableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    color: "white",
                    backgroundColor: row.color,
                    textAlign: "center",
                  }}
                >
                  {row.platform}
                </TableCell>
                {Object.keys(row.posts).map((date, idx) => (
                  <TableCell
                    key={idx}
                    align="center"
                    sx={{
                      backgroundColor:
                        row.posts[date] === "Canceled" ? "#CF7272FF" : "#dcf1ea",
                      color: "#0d271e",
                      fontWeight: "bold",
                    }}
                  >
                    {row.posts[date]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ThemeProvider>
  );
}
