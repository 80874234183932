import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  Box,
  Typography,
  TablePagination,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import InfoLeads from "./InfoLeads";

import CallLeadsAbandonCallChart from "./CallLeadsAbandonCallChart";
import InfoLeadsChart from "./InfoLeadsChart";
import CallLeadsDetailsTable from "./CallLeadsDetailsTable";

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: "#174736 ",
          color: "#ffffff",
          fontWeight: "bold",
        },
        body: {
          backgroundColor: "#ffffff",
          color: "#000000",
        },
      },
    },
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "auto",
  width: "80%",
  bgcolor: "background.paper",
  border: "2px solid #184332",
  boxShadow: 40,
  p: 4,
};

// Table Data
const columns = [
  "2023-10",
  "2023-11",
  "2023-12",
  "2024-01",
  "2024-02",
  "2024-03",
  "2024-04",
  "2024-05",
  "2024-06",
  "2024-07",
  "2024-08",
  "2024-09",
  "2024-10",
  "2024-11",
  "2024-12",
  "2025-01",
];

const rows = [
  {
    lead: "Patient Booked",
    data: [33, 11, 31, 23, 30, 46, 21, 25, 23, 8, 12, 13, 15, 17, 12, 19],
  },
  {
    lead: "New Patient Opportunity",
    data: [14, 10, 10, 12, 12, 11, 20, 14, 23, 60, 23, 30, 46, 21, 25, 23],
  },
  {
    lead: "Missed Call",
    data: [22, 15, 43, 54, 35, 19, 27, 31, 15, 12, 13, 9, 17, 14, 13, 14],
  },
  {
    lead: "Question",
    data: [77, 84, 77, 58, 42, 53, 38, 53, 29, 44, 27, 31, 15, 12, 13, 29],
  },
  {
    lead: "Abandon Call",
    data: [13, 9, 17, 14, 13, 14, 8, 13, 2, 6, 12, 12, 11, 20, 14, 23],
  },
];

// Updated Columns for Modal Table
const modalColumns = [
  "YearMonth",
  "Call Status",
  "Company Name",
  "Company ID",
  "Tracking Number",
  "Start Time",
  "Duration (seconds)",
  "Name",
  "Phone Number",
  "Email",
  "First-Time Caller",
  "City",
  "State",
  "Country",
  "Agent Name",
  "Agent Number",
  "Device Type",
  "Keywords",
  "Referrer",
  "Medium",
  "Landing Page",
  "Campaign",
  "Value",
  "Tags",
  "Qualified",
  "Recording Url",
  "Note",
  "Office Note",
];

// Generator function for mock data
const generatePopupData = (ln) => {
  return Array.from({ length: ln }, (_, i) => ({
    YearMonth: "2024-07",
    "Call Status": "Answered Call",
    "Company Name": "World AI Group",
    "Company ID": "12345",
    "Tracking Number": "T12345",
    "Start Time": "2024-07-31 12:28:45",
    "Duration (seconds)": "120",
    Name: "John Doe",
    "Phone Number": "647-515-0529",
    Email: "john.doe@example.com",
    "First-Time Caller": "TRUE",
    City: "Toronto",
    State: "ON",
    Country: "Canada",
    "Agent Name": "Agent X",
    "Agent Number": "A001",
    "Device Type": "Mobile",
    Keywords: "AI Call",
    Referrer: "Google",
    Medium: "CPC",
    "Landing Page": "www.example.com",
    Campaign: "AI Outreach",
    Value: "$200",
    Tags: "VIP",
    Qualified: "Yes",
    "Recording Url":
      "https://app.callrail.com/calls/CAL6ee45d9b396c44759f19b3ed2e3b165b/recording/redirect?access_key=d97e48b8200a6679c586",
    Note: "Follow-up required",
    "Office Note": "Escalated case",
  }));
};

const dataSets = [
  {
    name: "Call Leads",
    currentData: [13, 9, 17, 14, 13, 14, 8, 13, 2, 6],
    lastYearData: [7, 12, 10, 18, 11, 20, 15, 22, 17, 25],
  },
  {
    name: "Info Leads",
    currentData: [22, 15, 43, 54, 35, 19, 27, 31, 15, 12],
    lastYearData: [20, 18, 40, 50, 32, 25, 35, 42, 28, 38],
  },
];

const callLeadDetailsOptions = [
  { name: "Default View" },
  { name: "Details View" },
];

const CallLeadsTable = () => {
  const [open, setOpen] = useState(false);
  const [popupTitle, setPopupTitle] = useState("");
  const [popupData, setPopupData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5); // Set rows per page for pagination in main table
  const [popupPage, setPopupPage] = useState(0);
  const [popupRowsPerPage, setPopupRowsPerPage] = useState(5); // Set rows per page for pagination in modal table
  const [selectedChart, setSelectedChart] = useState(dataSets[0]);
  const [selectedCallLeadDetailsOption, setSelectedCallLeadDetailsOption] =
    useState(callLeadDetailsOptions[0]);
  const handleOpen = (lead, month, value) => {
    setPopupTitle(`${lead} - ${month} (Value: ${value})`);
    setPopupData(generatePopupData(value));
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  // Handle Pagination for Main Table
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Handle Pagination for Modal Table
  const handlePopupChangePage = (event, newPage) => {
    setPopupPage(newPage);
  };

  const handlePopupChangeRowsPerPage = (event) => {
    setPopupRowsPerPage(parseInt(event.target.value, 10));
    setPopupPage(0);
  };

  const handleChange = (event) => {
    const selected = dataSets.find(
      (dataset) => dataset.name === event.target.value
    );
    setSelectedChart(selected);
  };
  const callLeadDetailsOptionChangehandler = (event) => {
    const selected = callLeadDetailsOptions.find(
      (dataset) => dataset.name === event.target.value
    );
    setSelectedCallLeadDetailsOption(selected);
  };
  return (
    <div style={{ padding: "20px" }}>
      <div className="flex justify-between">
        <select
          onChange={handleChange}
          value={selectedChart.name}
          style={{ marginBottom: "20px", fontSize: "16px" }}
          className="bg-[#0D3B2E] text-white rounded-md py-2 px-2"
        >
          {dataSets.map(({ name }) => (
            <option key={name} value={name}>
              {name}
            </option>
          ))}
        </select>
        {/* {selectedChart.name === "Call Leads" && (
          <select
            onChange={callLeadDetailsOptionChangehandler}
            value={selectedCallLeadDetailsOption.name}
            style={{ marginBottom: "20px", fontSize: "16px" }}
            className="bg-[#0D3B2E] text-white rounded-md py-2 px-2"
          >
            {callLeadDetailsOptions.map(({ name }) => (
              <option key={name} value={name}>
                {name}
              </option>
            ))}
          </select>
        )} */}
      </div>
      {/* Main Table */}
      {selectedChart.name === "Call Leads" && (
        <>
          <div className="h-[360px] overflow-y-scroll">
            {selectedCallLeadDetailsOption.name === "Default View" && (
              <ThemeProvider theme={theme}>
                <TableContainer component={Paper} sx={{ overflowX: "auto" }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                        >
                          Call Leads
                        </TableCell>
                        {columns.map((month) => (
                          <TableCell
                            key={month}
                            style={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                          >
                            {month}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows
                        // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => (
                          <TableRow key={row.lead}>
                            <TableCell
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            >
                              {row.lead}
                            </TableCell>
                            {row.data.map((value, index) => (
                              <TableCell
                                key={index}
                                style={{
                                  cursor: "pointer",
                                }}
                                sx={{ textAlign: "center" }}
                                onClick={() =>
                                  handleOpen(row.lead, columns[index], value)
                                }
                              >
                                {value}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
              </ThemeProvider>
            )}
            {/* {selectedCallLeadDetailsOption.name === "Details View" && (
              <CallLeadsDetailsTable />
            )} */}
          </div>
          <CallLeadsAbandonCallChart />
        </>
      )}
      {selectedChart.name === "Info Leads" && (
        <>
          <div className="h-[480px] overflow-y-scroll">
            <InfoLeads />
          </div>
          <InfoLeadsChart />
        </>
      )}

      {/* Scrollable Modal with Pagination */}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxHeight: "90vh",
            bgcolor: "white",
            boxShadow: 24,
            p: 3,
            borderRadius: "10px",
            overflowY: "auto",
          }}
        >
          <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
            {popupTitle}
          </Typography>

          <ThemeProvider theme={theme}>
            <TableContainer
              component={Paper}
              sx={{ maxHeight: "50vh", overflowY: "auto" }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    {modalColumns.map((col) => (
                      <TableCell key={col}>{col}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {popupData
                    // .slice(popupPage * popupRowsPerPage, popupPage * popupRowsPerPage + popupRowsPerPage)
                    .map((item, index) => (
                      <TableRow key={index}>
                        {modalColumns.map((col) => (
                          <TableCell
                            key={col}
                            style={
                              index === 0
                                ? {
                                    position: "sticky",
                                    left: 0,
                                    backgroundColor: "#fff",
                                    zIndex: 1,
                                  }
                                : {}
                            }
                          >
                            {item[col] || "-"}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            {/* <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={popupData.length}
              rowsPerPage={popupRowsPerPage}
              page={popupPage}
              onPageChange={handlePopupChangePage}
              onRowsPerPageChange={handlePopupChangeRowsPerPage}
            /> */}
          </ThemeProvider>
        </Box>
      </Modal>
    </div>
  );
};

export default CallLeadsTable;
