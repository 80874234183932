import React, { useState } from "react";
import NavAndTopBar from "../../shared/layouts/NavAndTopBar";
import SumTable from "./SumTable";
import SumTableCharts from "./SumTableCharts";
import CallLeads from "./CallLeads";
import InfoLeads from "./InfoLeads";
import GoogleRanking from "./GoogleRanking";
import SocialMediaReportFacebook from "./SocialMediaReportFacebook";
import SocialMediaReportInstagram from "./SocialMediaReportInstagram";
import SocialMediaPostingCalendar from "./SocialMediaPostingCalendar";
import GoogleMapRanking from "./GoogleMapRanking";
import GMBReports from "./GMBReport";
import CompanyLogoAndMoto from "./CompanyLogoAndMoto";
import CallLeadsChart from "./CallLeadsChart";
import CallLeadsAbandonCallChart from "./CallLeadsAbandonCallChart";
import InfoLeadsChart from "./InfoLeadsChart";
import OpportunityBarChart from "./OppurtunityVsBookedChart";
import CallLeadsTable from "./CallLeadsTable";
import GoogleImage from "../../../assets/images/googleimage.png";
import GoogleMapImage from "../../../assets/images/google-maps.png";

export default function LeadReport() {
  return (
    <NavAndTopBar>
      <div className="bg-white p-4 flex flex-col gap-16">
        {/* {leadType === "leadOne" && <CallLeads />} */}
        {/* {leadType === "leadTwo" && <InfoLeads />} */}
        <div>
          <CompanyLogoAndMoto />
        </div>
        <div>
          <div className="flex justify-center">
            <p className="golden-txt  font-bold text-2xl my-2">
              AI Lead Report Summary
            </p>
          </div>
          <div className="flex flex-col gap-8">
            <SumTable />
            {/* <SumTableCharts /> */}
            <OpportunityBarChart />
            {/* <CallLeads /> */}
            <CallLeadsTable />
            {/* <CallLeadsChart /> */}
            {/* <CallLeadsAbandonCallChart /> */}
            {/* <InfoLeads /> */}
            {/* <InfoLeadsChart /> */}
          </div>
        </div>
        <div>
          <div className="flex justify-center">
            <p className="golden-txt font-bold text-2xl my-6">
              Website Ranking Report
            </p>
          </div>
          <div className="flex items-center gap-2">
            <img src={GoogleImage} width={80} alt="" />
            <p className="golden-txt font-bold text-lg">Ranking</p>
          </div>
          {/* <p className="golden-txt font-bold text-lg my-6">Google Ranking</p> */}
          <GoogleRanking />
          <div className="flex items-center">
            <img src={GoogleMapImage} width={130} alt="" />
            <p className="golden-txt font-bold text-lg">Ranking</p>
          </div>
          <GoogleMapRanking />
          <p className="golden-txt font-bold text-lg my-6">GMB Report</p>
          <GMBReports />
        </div>
        <div>
          <div className="flex justify-center">
            <p className="golden-txt font-bold text-2xl my-6">
              Social Media Report
            </p>
          </div>
          <div>
            <p className="golden-txt font-bold text-lg my-4">FaceBook</p>
            <SocialMediaReportFacebook />
            <p className="golden-txt font-bold text-lg my-2 mt-8">Instragram</p>
            <SocialMediaReportInstagram />
          </div>
        </div>
        <div>
          <div className="flex justify-center">
            <p className="golden-txt font-bold text-2xl my-2">
              Social Media Posting Calendar
            </p>
          </div>
          <SocialMediaPostingCalendar />
        </div>
      </div>
    </NavAndTopBar>
  );
}
