// import React, { useEffect, useState } from "react";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
// import AILeadService from "../../../data-access/services/aiLeadServices";

// export default function SocialMediaReportFacebook() {
//   const [leadData, setLeadData] = useState([]);
//   const [columns, setColumns] = useState(["Actions"]);
//   const [error, setError] = useState(null);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await AILeadService.getSocialMediaInstragramReports();
//         const data = response?.data?.data || [];

//         // Extract dynamic columns from the API data keys
//         const dynamicColumns = data.length > 0 ? Object.keys(data[0]).filter(key => key !== "insight_type") : [];
//         setColumns(["Actions", ...dynamicColumns]);

//         // Transform data for table rendering
//         const formattedData = data.map(item => ({
//           Actions: item.insight_type,
//           ...dynamicColumns.reduce((acc, key) => {
//             acc[key] = item[key] ?? 0; // Default to 0 if null
//             return acc;
//           }, {}),
//         }));
//         setLeadData(formattedData);
//       } catch (err) {
//         setError(err.message || "An error occurred while fetching data.");
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   if (loading) return <div>Loading...</div>;
//   if (error) return <div>Error: {error}</div>;

//   return (
//     <TableContainer component={Paper}>
//       <Table sx={{ minWidth: 650 }} aria-label="Social Media Report Table">
//         <TableHead className="bg-[#0d271e]">
//           <TableRow>
//             {columns.map((column) => (
//               <TableCell
//                 key={column}
//                 align="left"
//                 sx={{ whiteSpace: "nowrap", fontWeight: "bold", color: "#dcf1ea" }}
//               >
//                 {column}
//               </TableCell>
//             ))}
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {leadData.map((row, rowIndex) => (
//             <TableRow key={rowIndex}>
//               {columns.map((column) => (
//                 <TableCell
//                   key={column}
//                   style={{
//                     backgroundColor: "white",
//                   }}
//                 >
//                   {row[column] || 0}
//                 </TableCell>
//               ))}
//             </TableRow>
//           ))}
//         </TableBody>
//       </Table>
//     </TableContainer>
//   );
// }

import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: "#174736",
          color: "#ffffff",
          fontWeight: "bold",
        },
        body: {
          backgroundColor: "#ffffff",
          color: "#000000",
        },
      },
    },
  },
});

// const columns = [
//   "Actions",
//   "2024-02",
//   "2024-03",
//   "2024-04",
//   "2024-05",
//   "2024-06",
//   "2024-07",
//   "2024-08",
//   "2024-09",
// ];

// const rows = [
//   ["Reach", "2.1K", "8.4K", "16.4K", "4.9K", "4.1K", "3.4K", "15.6K", "5.6K"],
//   ["Account Visits", 71, 133, 130, 37, 58, 43, 65, 46],
//   ["Interactions", 0, 84, 194, 216, 204, 150, 181, 132],
//   ["Followers", 361, 451, 472, 476, 487, 495, 503, 506],
//   ["Organic Growth", 613, 982, 1064, 544, 769, 415, 2761, 408],
//   ["Link Clicks", 14, 35, 38, 7, 18, 10, 5, 31],
// ];

const columns = [
  "Actions",
  "2024-02",
  "2024-03",
  "2024-04",
  "2024-05",
  "2024-06",
  "2024-07",
  "2024-08",
  "2024-09",
  "2024-10",
  "2024-11",
  "2024-12",
  "2025-01",
];

const rows = [
  ["Reach", "2.1K", "8.4K", "16.4K", "4.9K", "4.1K", "3.4K", "15.6K", "5.6K", "6.2K", "6.5K", "6.1K", "5.9K"],
  ["Followers", 361, 451, 472, 476, 487, 495, 503, 506, 515, 525, 530, 535],
  ["Account Visits", 71, 133, 130, 37, 58, 43, 65, 46, 50, 60, 65, 70],
  ["Interactions", 0, 84, 194, 216, 204, 150, 181, 132, 140, 160, 175, 180],
  ["Organic Growth", 613, 982, 1064, 544, 769, 415, 2761, 408, 450, 470, 490, 510],
  ["Link Clicks", 14, 35, 38, 7, 18, 10, 5, 31, 35, 40, 45, 50],
];


const PaginatedTable = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <ThemeProvider theme={theme}>
      <TableContainer component={Paper} sx={{overflowX: "auto"}} className="p-4">
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  sx={{ textAlign: index !== 0 ? "center" : "left" }}
                  style={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                >
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <TableCell
                      key={cellIndex}
                      sx={{ textAlign: cellIndex !== 0 ? "center" : "left" }}
                    >
                      {cell}
                      {cellIndex > 1 &&
                        typeof cell === "number" &&
                        typeof row[cellIndex - 1] === "number" &&
                        (cell > row[cellIndex - 1] ? (
                          <ArrowUpward
                            fontSize="small"
                            color="success"
                            sx={{ ml: 0.5 }}
                          />
                        ) : cell < row[cellIndex - 1] ? (
                          <ArrowDownward
                            fontSize="small"
                            color="error"
                            sx={{ ml: 0.5 }}
                          />
                        ) : null)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </TableContainer>
    </ThemeProvider>
  );
};

export default PaginatedTable;
