import React, { useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";
import { LeadGenerationContext } from "../../../contexts/LeadGenerationContext";

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: "#174736",
          color: "#ffffff",
          fontWeight: "bold",
        },
        body: {
          backgroundColor: "#ffffff",
          color: "#000000",
        },
      },
    },
  },
});

const columns = [
  "No.",
  "Keywords",
  "2024-06",
  "2024-07",
  "2024-08",
  "2024-09",
  "2024-10",
  "2024-11",
  "2024-12",
  "2025-01",
];

const rows = [
  [1, "Dental Clinic in 'xxx'", "N/A", 9, 2, 2, 3, 4, 4, 5],
  [2, "Dental Care in 'xxx'", "N/A", 11, 7, 3, 4, 5, 6, 6],
  [3, "Best Dental Care in 'xxx'", "N/A", 8, 7, 6, 7, 8, 9, 9],
  [4, "Dental Clinic in 'xyz'", "N/A", 8, 2, 2, 3, 3, 4, 4],
  [5, "Dental clinic near 'yyy'", "N/A", 15, 2, 2, 2, 3, 3, 3],
  [6, "Dental Clinic in 'xx'", "N/A", 9, 2, 2, 3, 4, 4, 5],
  [7, "Best Dental Care in 'xx'", "N/A", 8, 7, 6, 7, 8, 9, 9],
];

const PaginatedTable = () => {
  const { googleMapRankingKeywords } = useContext(LeadGenerationContext); // Get selected keywords from context

  // Filter rows based on selected keywords
  const filteredRows =
  googleMapRankingKeywords.length > 0
      ? rows.filter((row) => googleMapRankingKeywords.includes(row[1]))
      : rows;

  return (
    <div className={`h-[${(googleMapRankingKeywords.length === 0 || googleMapRankingKeywords.length < 8) ? "450px" : (googleMapRankingKeywords.length*50).toString()+"px"} overflow-y-scroll`}>
      <ThemeProvider theme={theme}>
        <TableContainer
          component={Paper}
          sx={{ overflowX: "auto" }}
          className="p-4"
        >
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    key={index}
                    sx={{ textAlign: index !== 0 ? "center" : "left" }}
                    style={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                  >
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRows.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <TableCell
                      key={cellIndex}
                      sx={{ textAlign: cellIndex !== 0 ? "center" : "left" }}
                    >
                      {cell}
                      {cellIndex > 1 &&
                        typeof cell === "number" &&
                        typeof row[cellIndex - 1] === "number" &&
                        (cell > row[cellIndex - 1] ? (
                          <ArrowDownward
                            fontSize="small"
                            color="error"
                            sx={{ ml: 0.5 }}
                          />
                        ) : cell < row[cellIndex - 1] ? (
                          <ArrowUpward
                            fontSize="small"
                            color="success"
                            sx={{ ml: 0.5 }}
                          />
                        ) : null)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ThemeProvider>
    </div>
  );
};

export default PaginatedTable;
