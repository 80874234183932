// import React, { useEffect, useState } from "react";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
// import AILeadService from "../../../data-access/services/aiLeadServices";

// export default function SocialMediaReportFacebook() {
//   const [leadData, setLeadData] = useState([]);
//   const [columns, setColumns] = useState(["Actions"]);
//   const [error, setError] = useState(null);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await AILeadService.getSocialMediaFacebookReports();
//         const apiData = response?.data?.data || [];

//         if (apiData.length > 0) {
//           // Extract unique months from the response keys
//           const monthKeys = Object.keys(apiData[0]).filter(
//             (key) => key !== "insight_type"
//           );
//           setColumns(["Actions", ...monthKeys]);

//           // Map API data into the required format
//           const formattedData = apiData.map((item) => {
//             const { insight_type, ...monthlyData } = item;
//             return {
//               Actions: insight_type,
//               ...monthlyData,
//             };
//           });
//           setLeadData(formattedData);
//         } else {
//           setLeadData([]);
//         }
//       } catch (err) {
//         setError(err.message || "An error occurred while fetching data.");
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (error) {
//     return <div>Error: {error}</div>;
//   }

//   return (
//     <TableContainer component={Paper}>
//       <Table sx={{ minWidth: 650 }} aria-label="Social Media Report Table">
//         <TableHead className="bg-[#0d271e]">
//           <TableRow>
//             {columns.map((column) => (
//               <TableCell
//                 key={column}
//                 align="left"
//                 sx={{ whiteSpace: "nowrap", fontWeight: "bold", color: "#dcf1ea" }}
//               >
//                 {column}
//               </TableCell>
//             ))}
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {leadData.map((row, rowIndex) => (
//             <TableRow key={rowIndex}>
//               {columns.map((column) => (
//                 <TableCell
//                   key={column}
//                   style={{
//                     backgroundColor: "white",
//                   }}
//                 >
//                   {row[column] !== null && row[column] !== undefined
//                     ? row[column]
//                     : 0}
//                 </TableCell>
//               ))}
//             </TableRow>
//           ))}
//         </TableBody>
//       </Table>
//     </TableContainer>
//   );
// }

// import React from "react";
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   TablePagination,
// } from "@mui/material";
// import { createTheme, ThemeProvider } from "@mui/material/styles";

// const theme = createTheme({
//   components: {
//     MuiTableCell: {
//       styleOverrides: {
//         head: {
//           backgroundColor: "#174736",
//           color: "#ffffff",
//           fontWeight: "bold",
//         },
//         body: {
//           backgroundColor: "#ffffff",
//           color: "#000000",
//         },
//       },
//     },
//   },
// });

// const columns = [
//   "Actions",
//   "2024-02",
//   "2024-03",
//   "2024-04",
//   "2024-05",
//   "2024-06",
//   "2024-07",
//   "2024-08",
//   "2024-09",
// ];

// const rows = [
//   ["Reach", "5.4K", "21.1K", "22K", "1.9K", "9K", "6.2K", "9.5K", "16.8K"],
//   ["Account Visits", 227, 271, 273, 73, 46, 69, 113, 107],
//   ["Interactions", 41, 90, 76, 24, 16, 12, 28, 21],
//   ["Followers", 293, 365, 417, 419, 420, 422, 423, 424],
//   ["Organic Growth", 340, 191, 154, 194, 103, 62, 98, 52],
//   ["Link Clicks", 93, 119, 150, 3, 9, 2, 7, 62],
// ];

// const PaginatedTable = () => {
//   const [page, setPage] = React.useState(0);
//   const [rowsPerPage, setRowsPerPage] = React.useState(5);

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   return (
//     <ThemeProvider theme={theme}>
//       <TableContainer component={Paper} className="p-4">
//         <Table>
//           <TableHead>
//             <TableRow>
//               {columns.map((column, index) => (
//                 <TableCell
//                   key={index}
//                   sx={{ textAlign: index !== 0 ? "center" : "left" }}
//                 >
//                   {column}
//                 </TableCell>
//               ))}
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {rows
//               // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//               .map((row, rowIndex) => (
//                 <TableRow key={rowIndex}>
//                   {row.map((cell, cellIndex) => (
//                     <TableCell
//                       key={cellIndex}
//                       sx={{ textAlign: cellIndex !== 0 ? "center" : "left" }}
//                     >
//                       {/* {cell} */}
//                       {cell}
//                     </TableCell>
//                   ))}
//                 </TableRow>
//               ))}
//           </TableBody>
//         </Table>
//         {/* <TablePagination
//           rowsPerPageOptions={[5, 10]}
//           component="div"
//           count={rows.length}
//           rowsPerPage={rowsPerPage}
//           page={page}
//           onPageChange={handleChangePage}
//           onRowsPerPageChange={handleChangeRowsPerPage}
//         /> */}
//       </TableContainer>
//     </ThemeProvider>
//   );
// };

// export default PaginatedTable;


import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: "#174736",
          color: "#ffffff",
          fontWeight: "bold",
        },
        body: {
          backgroundColor: "#ffffff",
          color: "#000000",
        },
      },
    },
  },
});

// const columns = [
//   "Actions",
//   "2024-02",
//   "2024-03",
//   "2024-04",
//   "2024-05",
//   "2024-06",
//   "2024-07",
//   "2024-08",
//   "2024-09",
// ];

// const rows = [
//   ["Reach", "5.4K", "21.1K", "22K", "1.9K", "9K", "6.2K", "9.5K", "16.8K"],
//   ["Account Visits", 227, 271, 273, 73, 46, 69, 113, 107],
//   ["Interactions", 41, 90, 76, 24, 16, 12, 28, 21],
//   ["Followers", 293, 365, 417, 419, 420, 422, 423, 424],
//   ["Organic Growth", 340, 191, 154, 194, 103, 62, 98, 52],
//   ["Link Clicks", 93, 119, 150, 3, 9, 2, 7, 62],
// ];

const columns = [
  "Actions",
  "2024-02",
  "2024-03",
  "2024-04",
  "2024-05",
  "2024-06",
  "2024-07",
  "2024-08",
  "2024-09",
  "2024-10",
  "2024-11",
  "2024-12",
  "2025-01",
];

const rows = [
  ["Reach", "5.4K", "21.1K", "22K", "1.9K", "9K", "6.2K", "9.5K", "16.8K", "16K", "15.7K", "14.5K", "13.9K"], 
  ["Followers", 293, 365, 417, 419, 420, 422, 423, 424, 430, 435, 440, 445],
  ["Account Visits", 227, 271, 273, 73, 46, 69, 113, 107, 120, 130, 140, 155],
  ["Interactions", 41, 90, 76, 24, 16, 12, 28, 21, 25, 30, 35, 40],
  ["Organic Growth", 340, 191, 154, 194, 103, 62, 98, 52, 48, 45, 40, 38],
  ["Link Clicks", 93, 119, 150, 3, 9, 2, 7, 62, 65, 70, 75, 80],
];


const PaginatedTable = () => {
  return (
    <ThemeProvider theme={theme}>
      <TableContainer component={Paper} sx={{overflowX: "auto"}} className="p-4">
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  sx={{ textAlign: index !== 0 ? "center" : "left" }}
                  style={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                >
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <TableCell
                    key={cellIndex}
                    sx={{ textAlign: cellIndex !== 0 ? "center" : "left" }}
                  >
                    {cell}
                    {cellIndex > 1 && typeof cell === "number" && typeof row[cellIndex - 1] === "number" && (
                      cell > row[cellIndex - 1] ? (
                        <ArrowUpward fontSize="small" color="success" sx={{ ml: 0.5 }} />
                      ) : cell < row[cellIndex - 1] ? (
                        <ArrowDownward fontSize="small" color="error" sx={{ ml: 0.5 }} />
                      ) : null
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ThemeProvider>
  );
};

export default PaginatedTable;
