// import React, { useContext, useState } from "react";
// import NavAndTopBar from "../../shared/layouts/NavAndTopBar";
// import { LeadGenerationContext } from "../../../contexts/LeadGenerationContext";

// const googleRankingKeywords = [
//   [1, 'dental crown in "xxx"'],
//   [2, 'dental fillings service in "xxx"'],
//   [3, 'dentures in "xxx"'],
//   [4, 'root canal services in "xxx"'],
//   [5, 'best dental fillings in "xxx"'],
//   [6, 'cosmetic dentist in "xxx"'],
//   [7, 'Best dental care service in "xxx"'],
//   [8, 'best root canal in "xxx"'],
//   [9, "Teeth whitening services in 'xxx'"],
//   [10, 'Cosmetic dentist in "xxx"'],
//   [11, 'top choice dental clinic in "xxx"'],
//   [12, 'best dentistry in "xxx"'],
//   [13, 'Best dentist in "xxx"'],
//   [14, 'Emergency Dentist "xxx"'],
//   [15, '24/7 dentist in "xxx"'],
//   [16, 'Emergency Dental Service in "xxx"'],
//   [17, 'Award winning dental care in "xxx"'],
//   [18, 'Best pediatric dentistry service in "xxx"'],
// ];

// const googleMapRankingKeywords = [
//   [1, "Dental Clinic in 'xxx'"],
//   [2, "Dental Care in 'xxx'"],
//   [3, "Best Dental Care in 'xxx'"],
//   [4, "Dental Clinic in 'xyz'"],
//   [5, "Dental clinic near 'yyy'"],
//   [6, "Dental Clinic in 'xx'"],
//   [7, "Best Dental Care in 'xx'"],
// ];

// const SEOKeywordSelector = () => {
//   const [selectedContext, setSelectedContext] = useState("Google Ranking");
//   const [selectedKeywords, setSelectedKeywords] = useState([]);
//   const { setGoogleRankingKeywords, setGoogleMapRankingKeywords } = useContext(LeadGenerationContext);

//   const handleContextChange = (event) => {
//     setSelectedContext(event.target.value);
//     setSelectedKeywords([]); // Reset selection when context changes
//   };

//   const handleCheckboxChange = (keyword) => {
//     setSelectedKeywords((prev) =>
//       prev.includes(keyword)
//         ? prev.filter((item) => item !== keyword)
//         : [...prev, keyword]
//     );
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault();
    
//     if (selectedContext === "Google Ranking") {
//       setGoogleRankingKeywords(selectedKeywords);
//     } else {
//       setGoogleMapRankingKeywords(selectedKeywords);
//     }

//     console.log("Submitted Keywords:", selectedKeywords);
//   };

//   const keywordData =
//     selectedContext === "Google Ranking"
//       ? googleRankingKeywords
//       : googleMapRankingKeywords;

//   return (
//     <div>
//       <NavAndTopBar>
//         <div className="flex justify-center py-6">
//           <form
//             onSubmit={handleSubmit}
//             className="p-4 border rounded shadow-md w-80"
//           >
//             <label className="block text-lg font-semibold mb-2 golden-txt">
//               Select SEO Type
//             </label>
//             <select
//               value={selectedContext}
//               onChange={handleContextChange}
//               className="w-full p-2 border rounded mb-4"
//             >
//               <option value="Google Ranking">Google Ranking</option>
//               <option value="Google Map Ranking">Google Map Ranking</option>
//             </select>

//             <label className="block text-lg font-semibold mb-2 golden-txt">
//               Select SEO Keywords
//             </label>
//             <div className="border p-2 rounded">
//               {keywordData.map(([id, keyword]) => (
//                 <label key={id} className="flex items-center space-x-2 mb-1">
//                   <input
//                     type="checkbox"
//                     value={keyword}
//                     checked={selectedKeywords.includes(keyword)}
//                     onChange={() => handleCheckboxChange(keyword)}
//                   />
//                   <span className="golden-txt">{keyword}</span>
//                 </label>
//               ))}
//             </div>
//             <button
//               type="submit"
//               className="mt-4 w-full bg-blue-500 text-white p-2 rounded"
//             >
//               Submit
//             </button>
//           </form>
//         </div>
//       </NavAndTopBar>
//     </div>
//   );
// };

// export default SEOKeywordSelector;
import React, { useContext, useState, useEffect } from "react";
import NavAndTopBar from "../../shared/layouts/NavAndTopBar";
import { LeadGenerationContext } from "../../../contexts/LeadGenerationContext";

const googleRankingKeywords = [
  [1, 'dental crown in "xxx"'],
  [2, 'dental fillings service in "xxx"'],
  [3, 'dentures in "xxx"'],
  [4, 'root canal services in "xxx"'],
  [5, 'best dental fillings in "xxx"'],
  [6, 'cosmetic dentist in "xxx"'],
  [7, 'Best dental care service in "xxx"'],
  [8, 'best root canal in "xxx"'],
  [9, "Teeth whitening services in 'xxx'"],
  [10, 'Cosmetic dentist in "xxx"'],
  [11, 'top choice dental clinic in "xxx"'],
  [12, 'best dentistry in "xxx"'],
  [13, 'Best dentist in "xxx"'],
  [14, 'Emergency Dentist "xxx"'],
  [15, '24/7 dentist in "xxx"'],
  [16, 'Emergency Dental Service in "xxx"'],
  [17, 'Award winning dental care in "xxx"'],
  [18, 'Best pediatric dentistry service in "xxx"'],
];

const googleMapRankingKeywords = [
  [1, "Dental Clinic in 'xxx'"],
  [2, "Dental Care in 'xxx'"],
  [3, "Best Dental Care in 'xxx'"],
  [4, "Dental Clinic in 'xyz'"],
  [5, "Dental clinic near 'yyy'"],
  [6, "Dental Clinic in 'xx'"],
  [7, "Best Dental Care in 'xx'"],
];

const SEOKeywordSelector = () => {
  const { 
    googleRankingKeywords: selectedGoogleRankingKeywords, 
    googleMapRankingKeywords: selectedGoogleMapRankingKeywords, 
    setGoogleRankingKeywords, 
    setGoogleMapRankingKeywords 
  } = useContext(LeadGenerationContext);

  const [selectedContext, setSelectedContext] = useState("Google Ranking");
  const [selectedKeywords, setSelectedKeywords] = useState([]);

  // Load selected keywords from context on mount and when context changes
  useEffect(() => {
    if (selectedContext === "Google Ranking") {
      setSelectedKeywords(selectedGoogleRankingKeywords || []);
    } else {
      setSelectedKeywords(selectedGoogleMapRankingKeywords || []);
    }
  }, [selectedContext, selectedGoogleRankingKeywords, selectedGoogleMapRankingKeywords]);

  const handleContextChange = (event) => {
    setSelectedContext(event.target.value);
  };

  const handleCheckboxChange = (keyword) => {
    setSelectedKeywords((prev) =>
      prev.includes(keyword)
        ? prev.filter((item) => item !== keyword)
        : [...prev, keyword]
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (selectedContext === "Google Ranking") {
      setGoogleRankingKeywords(selectedKeywords);
    } else {
      setGoogleMapRankingKeywords(selectedKeywords);
    }

    console.log("Updated Context State:", { selectedContext, selectedKeywords });
  };

  const keywordData =
    selectedContext === "Google Ranking"
      ? googleRankingKeywords
      : googleMapRankingKeywords;

  return (
    <div>
      <NavAndTopBar>
        <div className="flex justify-center py-6">
          <form
            onSubmit={handleSubmit}
            className="p-4 border rounded shadow-md w-80"
          >
            <label className="block text-lg font-semibold mb-2 golden-txt">
              Select SEO Type
            </label>
            <select
              value={selectedContext}
              onChange={handleContextChange}
              className="w-full p-2 border rounded mb-4"
            >
              <option value="Google Ranking">Google Ranking</option>
              <option value="Google Map Ranking">Google Map Ranking</option>
            </select>

            <label className="block text-lg font-semibold mb-2 golden-txt">
              Select SEO Keywords
            </label>
            <div className="border p-2 rounded">
              {keywordData.map(([id, keyword]) => (
                <label key={id} className="flex items-center space-x-2 mb-1">
                  <input
                    type="checkbox"
                    value={keyword}
                    checked={selectedKeywords.includes(keyword)}
                    onChange={() => handleCheckboxChange(keyword)}
                  />
                  <span className="golden-txt">{keyword}</span>
                </label>
              ))}
            </div>
            <button
              type="submit"
              className="mt-4 w-full bg-blue-500 text-white p-2 rounded"
            >
              Submit
            </button>
          </form>
        </div>
      </NavAndTopBar>
    </div>
  );
};

export default SEOKeywordSelector;
