import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const dataSets = [
  {
    name: "Call Back",
    currentData: [4, 2, 9, 17, 1, 1, 19, 9, 16, 8],
    lastYearData: [1, 2, 7, 12, 4, 2, 14, 7, 15, 7],
  },
  {
    name: "No Answer",
    currentData: [3, 13, 16, 9, 7, 19, 15, 2, 12, 1],
    lastYearData: [1, 6, 9, 12, 15, 12, 9, 5, 10, 4],
  },
  {
    name: "Not Interested",
    currentData: [2, 12, 17, 11, 16, 16, 4, 11, 10, 4],
    lastYearData: [1, 8, 19, 8, 19, 10, 8, 10, 7, 8],
  },
  {
    name: "Booked",
    currentData: [15, 20, 30, 20, 18, 2, 7, 8, 1, 8],
    lastYearData: [5, 10, 32, 18, 12, 6, 9, 5, 4, 6],
  },
  {
    name: "Interested - Call Back",
    currentData: [0, 1, 9, 6, 5, 13, 19, 16, 11, 11],
    lastYearData: [0, 2, 8, 9, 4, 16, 14, 19, 14, 8],
  },
];

const InfoLeadsChart = () => {
  const [selectedChart, setSelectedChart] = useState(dataSets[0]);

  const handleChange = (event) => {
    const selected = dataSets.find(
      (dataset) => dataset.name === event.target.value
    );
    setSelectedChart(selected);
  };

  const chartOptions = {
    chart: {
      id: `${selectedChart.name.toLowerCase().replace(/ /g, "-")}-chart`,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      height: 350,
    },
    xaxis: {
      categories: [
        "2023-10",
        "2023-11",
        "2023-12",
        "2024-01",
        "2024-02",
        "2024-03",
        "2024-04",
        "2024-05",
        "2024-06",
        "2024-07",
      ],
      title: { text: "Months" },
    },
    yaxis: { title: { text: "Interactions" } },
    stroke: {
      width: [5, 5],
      curve: "smooth",
      dashArray: [0, 2],
    },
    title: {
      text: `${selectedChart.name} Comparison: Current Year vs Last Year`,
      align: "center",
    },
    colors: ["#0D3B2E", "#FF4560"],
    legend: { position: "top" },
  };

  const series = [
    {
      name: `${selectedChart.name} (2023-2024)`,
      data: selectedChart.currentData,
    },
    {
      name: `${selectedChart.name} (2022-2023)`,
      data: selectedChart.lastYearData,
    },
  ];

  return (
    <div className="mt-6">
      <div>
        <select
          onChange={handleChange}
          value={selectedChart.name}
          style={{ marginBottom: "20px", fontSize: "16px" }}
          className="bg-[#174736] text-white rounded-md py-2 px-2"
        >
          {dataSets.map(({ name }) => (
            <option key={name} value={name}>
              {name}
            </option>
          ))}
        </select>
      </div>

      <ReactApexChart
        options={chartOptions}
        series={series}
        type="line"
        height={400}
      />
    </div>
  );
};

export default InfoLeadsChart;
