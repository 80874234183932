import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import AILeadService from "../../../../data-access/services/aiLeadServices";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: "#174736",
          color: "#ffffff",
          fontWeight: "bold",
        },
        body: {
          backgroundColor: "#ffffff",
          color: "#000000",
        },
      },
    },
  },
});

export default function InfoLeads() {
  const [leadData, setLeadData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await AILeadService.getInfoLeads();
        const data = response?.data?.data || [];

        // Dynamically extract unique columns from the API response
        const uniqueColumns = Array.from(
          new Set(data.flatMap((row) => Object.keys(row)))
        );
        setColumns(uniqueColumns);

        setLeadData(data);
      } catch (err) {
        setError(err.message || "An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSort = (columnKey) => {
    const isAsc =
      sortConfig.key === columnKey && sortConfig.direction === "asc";
    setSortConfig({ key: columnKey, direction: isAsc ? "desc" : "asc" });
  };

  const sortedData = React.useMemo(() => {
    if (!sortConfig.key) return leadData;

    return [...leadData].sort((a, b) => {
      const aValue = a[sortConfig.key] || 0;
      const bValue = b[sortConfig.key] || 0;

      if (typeof aValue === "number" && typeof bValue === "number") {
        return sortConfig.direction === "asc"
          ? aValue - bValue
          : bValue - aValue;
      }

      return sortConfig.direction === "asc"
        ? aValue.toString().localeCompare(bValue.toString())
        : bValue.toString().localeCompare(aValue.toString());
    });
  }, [leadData, sortConfig]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <ThemeProvider theme={theme}>
      <TableContainer
        component={Paper}
        sx={{ overflowX: "auto" }}
        className="p-4"
      >
        <Table sx={{ minWidth: 650 }} aria-label="dynamic table">
          <TableHead className="bg-[#0d271e]">
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={column}
                  sx={{ textAlign: index !== 0 ? "center" : "left" }}
                  style={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                >
                  <TableSortLabel
                    active={sortConfig.key === column}
                    onClick={() => handleSort(column)}
                    style={{ fontWeight: "bold", color: "white" }}
                  >
                    {column === "Appointment Booked" ? "Info Leads" : column}
                    {sortConfig.key === column
                      ? sortConfig.direction === "asc"
                        ? " ↑"
                        : " ↓"
                      : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {columns.map((col, colIndex) => (
                  <TableCell
                    key={colIndex}
                    sx={{ textAlign: colIndex !== 0 ? "center" : "left" }}
                  >
                    {row[col] || 0}
                  </TableCell>
                ))}
              </TableRow>
            ))}
            {/* Grand Total Row */}
            {/* <TableRow>
            <TableCell style={{ fontWeight: "bold" }}>Grand Total</TableCell>
            {columns.slice(1).map((col, colIndex) => (
              <TableCell key={colIndex} style={{ fontWeight: "bold", color: "white" }}>
                {leadData.reduce((sum, row) => sum + (row[col] || 0), 0)}
              </TableCell>
            ))}
          </TableRow> */}
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>Grand Total</TableCell>
              {columns.slice(1).map((col, colIndex) => (
                <TableCell
                  key={colIndex}
                  style={{ fontWeight: "bold" }}
                  sx={{ textAlign: "center" }}
                >
                  {leadData.reduce((sum, row) => sum + (row[col] || 0), 0)}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </ThemeProvider>
  );
}
