import React from "react";
import Chart from "react-apexcharts";

const StackedBarChart = () => {
  // Define months
  // const months = [
  //   "2023-10",
  //   "2023-11",
  //   "2023-12",
  //   "2024-01",
  //   "2024-02",
  //   "2024-03",
  //   "2024-04",
  //   "2024-05",
  //   "2024-06",
  //   "2024-07",
  // ];

  // // Current year data
  // const opportunity = [70, 68, 105, 90, 102, 110, 72, 50, 58, 69];
  // const booked = [55, 30, 60, 40, 45, 46, 28, 32, 25, 24];

  const months = [
    "2023-10",
    "2023-11",
    "2023-12",
    "2024-01",
    "2024-02",
    "2024-03",
    "2024-04",
    "2024-05",
    "2024-06",
    "2024-07",
    "2024-08",
    "2024-09",
    "2024-10",
    "2024-11",
    "2024-12",
    "2025-01",
  ];
  
  // Current year data
  const opportunity = [70, 68, 105, 90, 102, 110, 72, 50, 58, 69, 75, 80, 85, 88, 90, 95];
  const booked = [55, 30, 60, 40, 45, 46, 28, 32, 25, 24, 30, 35, 38, 40, 42, 45];
  

  // Last year data (random variation of 10-20%)
  const lastYearOpportunity = opportunity.map((val) =>
    Math.round(val * (0.8 + Math.random() * 0.4))
  );
  const lastYearBooked = booked.map((val) =>
    Math.round(val * (0.8 + Math.random() * 0.4))
  );

  // Stacked bars: First bar for Opportunities, second for Booked
  const series = [
    { name: "Opportunity", data: opportunity, group: "current year" },
    { name: "Booked", data: booked, group: "current year" },
    {
      name: "Last Year Opportunity",
      data: lastYearOpportunity,
      group: "last year",
    },
    { name: "Last Year Booked", data: lastYearBooked, group: "last year" },
  ];

  const options = {
    chart: { type: "bar", stacked: true, toolbar: { show: false } },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "40%",
        borderRadius: 4,
      },
    },
    colors: ["#0d271e", "#068F71FF", "#A36A3E", "#F5BC71FF"],
    xaxis: { categories: months },
    yaxis: { title: { text: "Count" } },
    legend: { position: "top" },
    tooltip: { shared: true, intersect: false },
    dataLabels: { enabled: false },
  };

  return (
    <div>
      <h2 className="text-left mb-4 golden-txt font-bold text-lg">
        Opportunity vs. Booked
      </h2>
      <Chart options={options} series={series} type="bar" height={400} />
    </div>
  );
};

export default StackedBarChart;
