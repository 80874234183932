import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";

const StickyTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  whiteSpace: "nowrap",
  position: "sticky",
  left: 0,
  // background: theme.palette.background.default,
  zIndex: 1000,
}));

// const StickyTableCell = withStyles((theme) => ({
//   head: {
//     left: 0,
//     position: "sticky",
//     zIndex: 99,
//   },
//   body: {
//     minWidth: "50px",
//     left: 0,
//     position: "sticky",
//     zIndex: 99,
//   },
// }))(TableCell);

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: "#174736 ",
          color: "#ffffff",
          fontWeight: "bold",
        },
        body: {
          backgroundColor: "#ffffff",
          color: "#000000",
        },
      },
    },
  },
});

const columns = [
  "Description",
  "2023-10",
  "2023-11",
  "2023-12",
  "2024-01",
  "2024-02",
  "2024-03",
  "2024-04",
  "2024-05",
  "2024-06",
  "2024-07",
  "2024-08",
  "2024-09",
  "2024-10",
  "2024-11",
  "2024-12",
  "2025-01",
];

const rows = [
  [
    "Total Number of Opportunity",
    71,
    69,
    109,
    92,
    111,
    115,
    73,
    49,
    53,
    69,
    75,
    80,
    77,
    85,
    90,
    88,
  ],
  [
    "Total Booked",
    54,
    31,
    61,
    43,
    48,
    48,
    28,
    33,
    24,
    23,
    27,
    30,
    29,
    35,
    38,
    36,
  ],
];

const PaginatedTable = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="overflow-x-scroll">
      <ThemeProvider theme={theme}>
        <TableContainer
          component={Paper}
          sx={{ overflowX: "auto" }}
          // className="p-4"
        >
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column, index) =>
                  index === 0 ? (
                    <StickyTableCell
                      key={index}
                      style={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                    >
                      {column}
                    </StickyTableCell>
                  ) : (
                    <TableCell
                      key={index}
                      style={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                    >
                      {column}
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => ( */}
              {rows.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {row.map((cell, cellIndex) =>
                    cellIndex === 0 ? (
                      <StickyTableCell
                        key={cellIndex}
                        // sx={{ textAlign: cellIndex !== 0 ? "center" : "left" }}
                        // style={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                        className="text-right"
                      >
                        {cell}
                      </StickyTableCell>
                    ) : (
                      <TableCell
                        key={cellIndex}
                        sx={{ textAlign: cellIndex !== 0 ? "center" : "left" }}
                        style={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                        className="text-right"
                      >
                        {cell}
                      </TableCell>
                    )
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {/* <TablePagination
          rowsPerPageOptions={[5, 10]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
        </TableContainer>
      </ThemeProvider>
    </div>
  );
};

export default PaginatedTable;
